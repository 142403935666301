.reminders-list {
  position: absolute;
  width: 100%;
  height: 100%;

  &__buttons {
    display: flex;
    @media(max-width: 756px){
      flex-direction: column;
    }
    a:first-child {
      margin-right: 20px;
      @media(max-width: 756px){
        margin-bottom: 20px;
      }
    }
    span {
      margin-right: 5px;
    }
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0 15px 17px;
    @media(max-width: 756px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  &__title {
    h2 {
      font-size: 17px;
      color: $darkBlue;
      font-family: $fontLato;
      font-weight: bold;
      text-transform: uppercase;
    }
    @media(max-width: 756px) {
      margin-bottom: 20px;
    }
  }
  &__table {
    overflow-x: auto;
    @media screen and (max-width: 1200px) {
      table {
        table-layout: unset;
        tbody {
          .actions {
            display: flex;
            flex-direction: row;
            align-items: center;
            //   height: -webkit-fill-available;
          }
          tr {
            height: unset;
          }
          td {
            vertical-align: middle;
            white-space: nowrap;
            padding: 2px 15px 10px 15px;
          }
        }
      }
    }
  }
  a{
    text-decoration: none;
    color: inherit;
  }
}
.remind{
    font-weight: normal;
    strong{
      font-weight: bold;
    }
}