.pagination {
  font-family: $fontLato;
  font-weight: bold;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  .react-select {
    width: 55px;
    &__control {
      border-radius: 6px;
      border: 1px solid rgba(86, 93, 112, 1);
      min-height: 25px !important;
      height: 25px;
      color: rgba(46, 46, 46, 1);
    }
    &__menu {
      text-align: center;
      padding: 0;
      z-index: 150;
    }
    &__option {
      padding: 2px 0;
      font-size: 13px;
      color: rgba(46, 46, 46, 1);
    }
    &__value-container {
      padding: 0px 10px;
      height: 25px;
      font-size: 13px;
    }
    &__indicator-separator {
      display: none;
    }
    &__dropdown-indicator {
      padding: 0px 5px;
      img {
        height: 10px;
        margin-top: unset;
        margin-right: unset;
      }
    }
    &__clear-indicator {
      display: none;
    }
  }
  p {
    font-size: 13px;
    color: rgba(46, 46, 46, 0.52);
    margin-right: 12px;
  }
  &__list {
    margin-left: 12px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding: 10px 0;
  }
  &__list-element {
    min-width: 20px;
    height: 28px;
    // border: 1px solid #827e7e;
    // box-shadow: 0px 0px 3px rgba(156, 156, 156, 1);
    //box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    color: rgba(46, 46, 46, 0.34);
    display: flex;
    margin: 0 2px;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    cursor: pointer;
    // &--active {
    //   // background-color: #b7b7b7;
    //   background-color: #5e75c5;
    //   color: #fff;
    // }
    &--active,
    &:hover {
      color: rgba(46, 46, 46, 1);
    }
    &--disabled {
      color: #e2e2e2;
      &:hover {
        background-color: #fff;
        color: #e2e2e2;
      }
    }
  }
}
