table {
  border-collapse: separate;
  border-spacing: 0 0.4rem;
  width: 100%;
  padding: 0 15px;
  table-layout: fixed;
  font-family: $fontLato;
  //   @media screen and (max-width: 1200px) {
  //     table-layout: unset;
  //   }
  thead {
    height: 35px;
    line-height: 20px;
    border-radius: 3px;
    tr {
      th {
        border-bottom: 1px solid #707070;
        font-size: 12px;
        font-weight: 600;
        color: #2e2e2e;
        padding: 0 3px 13px 10px;
        // white-space: nowrap;
        text-align: left;
        // word-wrap: break-word;
        white-space: nowrap;
        input {
          background-color: #b2b2b2;
          border-radius: 6px;
          border: none;
          height: 22px;
          width: 100%;
          margin-top: 10px;
          padding: 0 7px;
          line-height: 22px;
          outline: none;
        }
      }
      .actions {
        width: 130px;
      }
      .remind {
        width: 235px;
      }
    }
  }
  tbody {
    &:before {
      content: '';
      margin-top: 5px;
      display: block;
    }
    // .hoverable {
    //   &:hover {
    //     background-color: #f1f1f1bf;
    //     cursor: pointer;
    //   }
    // }
    .colored {
      background-color: #f8bebe;
    }
    tr {
      // &:hover {
      //   background-color: #f0f0f0d6;
      // }
      //   box-shadow: -1px 2px 1px rgba(60, 60, 60, 0.24), 1px 1px 0 rgba(60, 60, 60, 0.24);
      height: 40px;
      line-height: 15px;
      border-radius: 4px;
      &:last-of-type {
        td {
          border: none;
        }
      }
      .action-icons {
        display: flex;
        justify-content: center;
        align-items: center;
        height: inherit;
        .fa-headphones-alt {
          font-size: 1.5rem;
          color: #bab7b7;
          margin-right: 10px;
          &:hover {
            color: #727ce5;
          }
        }
        .fa-heart {
          font-size: 1.5rem;
          color: #bab7b7;
          margin-right: 10px;
        }
        .fa-download {
          font-size: 1.2rem;
          color: #bab7b7;
        }
      }
    }

    td {
      //   vertical-align: middle;
      padding: 2px 15px 25px 15px;
      border-bottom: 1px solid rgba(112, 112, 112, 0.22);
      //   border-right: 1px solid rgba(112, 112, 112, 0.22);
      position: relative;
      font-size: 12px;
      font-weight: bold;
      color: #2e2e2e;
      //   @media screen and (max-width: 1200px) {
      //     white-space: nowrap;
      //   }
      &:not(:last-child) {
        &:after {
          content: '';
          position: absolute;
          right: 0;
          z-index: 100;
          top: 0;
          // transform: translateY(-50%);
          width: 1px;
          height: 85%; /* or 100px */
          background: rgba(112, 112, 112, 0.22);
          &:last-of-type {
            display: none;
          }
        }
      }
    }
    .actions {
      vertical-align: -webkit-baseline-middle;
    }
    .case {
      color: $darkBlue;
    }
    .remind {
      font-weight: 400;
      line-height: 19px;
      strong {
        font-weight: 900;
      }
    }
  }
}
.filled-thead {
  tr {
    border-radius: 6px;
    th {
      background-color: #f3f3f3;
      padding: 8px 15px;
      border-bottom: 0px;
      &:first-of-type {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
      }
      &:last-of-type {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
      }
    }
  }
}
.files__table--content--name {
  margin-left: 17px;
}