.files {
  padding: 20px;
  font-family: 'Lato', sans-serif;
  &__title {
    margin: 50px 0 50px 0;
  }
  &__table {
    width: auto;

    &--contentWrapper {
      display: flex;
      gap: 30px;
      margin-top: 10px;
    }

    &--header {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-bottom: 10px;
      margin-top: 20px;
      border-bottom: 1px solid lightgray;
      .data {
        display: flex;
      }
      .head {
        display: flex;
        margin-left: 30px;
        width: 260px;
      }
      .data,.head {
        img {
          margin-left: 10px;
        }
      }
    }
    &--content {
      .btn-action {
        float: none;
        margin-bottom: 10px;
      }
      .file {
        margin-bottom: 11px;
        cursor: pointer;
        width: 260px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: 10px;
      }
      &--file {
        display: flex;
      }
      .data {
        margin-right: 16px;
      }
      // .tile{
      //   height: 40px;
      //   margin-bottom: 10px
      // }
    }

    &--goBack {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 290px;
      height: 50px;

      .lds-dual-ring {
        position: relative;
        top: 18px;
      }

      &--arrow {
        cursor: pointer;
        width: 190px;
        display: flex;
        align-items: center;
        justify-content: space-around;
      }
    }
  }
}
