// input::placeholder {
//   color: $darkBlue;
//   font-family: $fontLato;
// }
.DraftEditor-root {
  background-color: #fff;
  border-radius: 6px;
  border: none;
  width: 100%;
  padding: 19px 10px;
}
.form__field {
  position: relative;
  margin: 0 auto 0 auto;
  // padding-left: 30px;
  // height: 5px;
  font-family: $fontLato;
  .field-label {
    // position: absolute;
    font-weight: 400;
    color: $dark;
    font-size: 12px;
    pointer-events: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0 5px 2px;
  }
  .react-select {
    &__control {
      border-style: unset;
      border-radius: 0;
      // min-height: 24px;
      height: 33px;
      background-color: #fff;
      margin: 5px 0 20px 0;
      border-radius: 5px;

      font-size: 12px !important;
      padding: 0 0 0 10px;
      line-height: 0;
      font-family: 'Lato', sans-serif;
      font-weight: 400;
      color: rgba(26, 26, 26, 0.65);
      &:hover {
        border-color: #e9e6e6;
      }
      &--is-focused {
        outline: none;
        box-shadow: none;
        // height: 33px;
        // line-height: 33px;
      }
    }
    &--is-multi {
      &__control {
        border-style: unset;
        border-bottom: 1px solid #e9e6e6;
        border-radius: 0;
        min-height: 24px;
        background-color: transparent;
        &:hover {
          border-color: #e9e6e6;
        }
        &--is-focused {
          outline: none;
          box-shadow: none;
          min-height: 24px;
        }
      }
      &__value-container {
        padding: 0;
        min-height: 24px;
        top: -7px;
      }
    }
    &__menu {
      text-align: left;
    }
    &__value-container {
      padding: 0;
      min-height: 24px;
      // top: -7px;
    }
    &__indicators {
      // top: -6px;
      position: relative;
      img {
        height: 15px;
        margin-right: 3px;
      }
    }
    &__single-value {
      color: #212020;
      font-weight: 300;
      // font-size: 1.1rem;
      margin-left: 0;
      overflow: visible;
    }
  }
  &--default {
    input,
    select,
    textarea {
      background-color: #fff !important;
      border-radius: 6px;
      border: none;
      height: 33px;
      width: 100%;
      font-size: 12px !important;
      padding: 19px 10px;
      font-family: $fontLato;
      font-weight: 400;
      color: rgba(26, 26, 26, 0.65);
    }
    textarea {
      height: 100px;
      padding: 10px 10px;
    }
  }
  &--gray {
    input {
      background-color: #f3f3f3 !important;
    }
  }
  &--error {
    input,
    textarea,
    .react-select__control {
      border: 1px solid #e20d0d !important;
    }
    .react-select__input {
      input {
        border: none !important;
      }
    }
  }
  // &--checkbox {
  //   height: 20px;
  //   display: flex;
  //   align-items: center;
  //   padding-left: 25px;
  //   margin: -15px auto 25px auto;
  //   font-size: 14px;
  //   color: #969090;
  // }
  &--checkbox {
    width: 100%;
    .checkbox__details {
      display: flex;
      line-height: 25px;
      margin: 5px;
      label {
        font-weight: 400 !important;
        color: rgba(26, 26, 26, 0.65);
        font-family: $fontLato;
        font-size: 14px;
      }
    }
    label {
      order: 2;
      cursor: pointer;
      user-select: none;
      width: calc(100%);
    }
    input {
      cursor: pointer;
      flex: 0 0 30px;
    }
  }
  &--disabled {
    input[type='text']::placeholder {
      color: #dcdbdb;
    }
  }
  .icon {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
  }
  input,
  select,
  textarea {
    // font-size: 1.1rem;
    width: 100%;
    border: none;
    font-size: 15px;
    background-color: $lightCream;
    // border-bottom: 1px solid #e9e6e6;
    // border-bottom: 2px solid #efefefe3;
    outline: none;
    // font-family: $fontLato;
    // color: #212020;
    // font-weight: 300;
    option {
      color: #444;
    }
  }
  input[type='checkbox'] {
    width: 20px;
    height: 17px;
  }
  &--label-error {
    // position: relative;
    // top: 4px;
    // text-align: right;
    font-family: $fontLato;
    // font-size: 13px;
    // font-weight: 500;
    color: #e20d0d;
    // height: 13px;
    // span {
    margin-left: 3px;
    position: relative;
    animation: show 0.25s ease-in;
    // }
  }
  @keyframes show {
    0% {
      right: -40px;
    }
    50% {
      right: 0px;
    }
    80% {
      right: -10px;
    }
    100% {
      right: 0px;
    }
  }
}

input[type='checkbox'] {
  display: none;
}
input[type='checkbox'] + .custom-box {
  display: inline-block;
  position: relative;
  top: 3px;
  width: 24px;
  height: 22px;
  margin: -1px 0px 0 0;
  vertical-align: middle;
  background: white left top no-repeat;
  border: 1px solid #ccc;
  cursor: pointer;
  border: 1px solid #707070;
  border-radius: 3px;
  background: transparent;
}
input[type='checkbox']:checked + .custom-box {
  background-color: $darkBlue;
  background-image: url('../../../../Img/Icons/checked-icon.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 70%;
}

input[type='checkbox'] + .custom-box {
  margin-right: 15px;
}
