@import './_reset';
@import './_variables';
@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700,900&display=swap');

.newclassaddtestcicd {
  display: none;
  // display: block;
}
html {
  scroll-behavior: smooth;
}
body {
  line-height: 1.5;
  font-size: 16px;
  // font-family: 'Montserrat', sans-serif;
}
.email {
  text-decoration: none;
  color: #000000;
}
.pointer {
  cursor: pointer;
}
#remindAt {
  padding-bottom: 30px;
}

.btn {
  background: none;
  outline: none;
  border: 0;

  border-radius: 5px;
  display: block;
  font-weight: 400;
  font-size: 17px;
  padding: 0 30px;
  // margin: 20px;
  text-decoration: none;
  font-family: $fontLato;
  text-align: center;
  cursor: pointer;
  &--default {
    height: 48px;
    line-height: 48px;
    background-color: $darkBlue;
    font-weight: black;
    color: #fff;
    border-radius: 3px;
    width: 100%;
    text-transform: uppercase;
    font-size: 13px;
    &:hover {
      // background-color: #f5f4f4;
      background-color: #3d3d69;
    }
  }
  &--main {
    width: auto;
    border-radius: 6px;
    font-weight: 900;
    letter-spacing: 1px;
  }
  &--add-client {
    height: 30px;
    line-height: 30px;
    background-color: $darkBlue;
    font-weight: black;
    color: #fff;
    border-radius: 3px;
    width: 130px;
    text-transform: uppercase;
    font-size: 12px;
    display: block;
    margin-top: 20px;
    &:hover {
      // background-color: #f5f4f4;
      background-color: #3d3d69;
    }
  }
  &--cancel {
    height: 48px;
    //line-height: 48px;
    background-color: #fff;
    font-weight: 700;
    color: $darkBlue;
    width: 150px;
    text-transform: uppercase;
    border: 2px solid rgba(243, 239, 236, 1);
    border-radius: 3px;
    font-size: 13px;
    letter-spacing: 1px;
    &:hover {
      // background-color: #f5f4f4;
      background-color: $lightCream;
    }
  }
  &--confirm {
    height: 48px;
    line-height: 48px;
    background-color: $orange;
    font-weight: 700;
    color: #ffffff;
    width: 150px;
    text-transform: uppercase;
    font-size: 13px;
    border-radius: 3px;
    letter-spacing: 1px;
    &:hover {
      // background-color: #f5f4f4;
      background-color: #e04f02;
    }
  }
  &--login {
    font-size: 17px;
  }
  &--navigation {
    height: 37px;
    // width: 85px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-size: 13px;
    margin: 0;
    line-height: 37px;
    padding: 0 5px;
    box-sizing: content-box;
    span {
      margin-right: 7px;
    }
    background-color: unset;
    color: $darkBlue;
    font-weight: bold;
    &:hover {
      font-weight: 900;
    }
  }
  &--center {
    margin-left: auto;
    margin-right: auto;
  }
  &--delete {
    background-color: #e80000;
    color: #fff;
    &:hover {
      background-color: #d80202;
    }
  }
  &--disabled {
    color: #e4e4e4;
    cursor: not-allowed;
    &:hover {
      background-color: #fff;
      box-shadow: 0px 1px 5px rgba(156, 156, 156, 0.32);
    }
  }
}
.btn-action {
  background: none;
  outline: none;
  border: 0;

  border-radius: 6px;
  display: block;
  background-color: #f3f3f3;
  width: 25px;
  height: 25px;
  float: left;
  margin-right: 8px;
  cursor: pointer;
  &--reply {
    background-image: url(../Img/Icons/ButtonIcons/reply-icon.svg);
    background-repeat: no-repeat;
    background-position: center;
    &:hover {
      background-image: url(../Img/Icons/ButtonIcons/reply-hover-icon.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-color: $darkBlue;
    }
  }
  &--date {
    background-image: url(../Img/Icons/ButtonIcons/date-icon.svg);
    background-repeat: no-repeat;
    background-position: center;
    &:hover {
      background-image: url(../Img/Icons/ButtonIcons/date-hover-icon.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-color: $darkBlue;
    }
  }

  &--folder {
    background-image: url(../Img/Icons/ButtonIcons/folder-icon.svg);
    background-repeat: no-repeat;
    background-position: center;
    &:hover {
      background-image: url(../Img/Icons/ButtonIcons/folder-hover-icon.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-color: $darkBlue;
    }
  }

  &--cancel {
    background-image: url(../Img/Icons/ButtonIcons/cancel-icon.svg);
    background-repeat: no-repeat;
    background-position: center;
    &:hover {
      background-image: url(../Img/Icons/ButtonIcons/cancel-hover-icon.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-color: $orange;
    }
  }
  &--edit {
    background-image: url(../Img/Icons/ButtonIcons/edit-icon.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 16px;
    &:hover {
      background-image: url(../Img/Icons/ButtonIcons/edit-hover-icon.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-color: $darkBlue;
    }
  }
  &--search {
    background-image: url(../Img/Icons/ButtonIcons/search-icon.svg);
    background-repeat: no-repeat;
    background-position: center;
    &:hover {
      background-image: url(../Img/Icons/ButtonIcons/search-hover-icon.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-color: $darkBlue;
    }
  }
  &--open {
    background-image: url(../Img/Icons/ButtonIcons/open-icon.svg);
    background-repeat: no-repeat;
    background-position: center;
    &:hover {
      background-image: url(../Img/Icons/ButtonIcons/open-hover-icon.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-color: $darkBlue;
    }
  }
  &--favourite {
    background-image: url(../Img/Icons/ButtonIcons/favorite-icon.svg);
    background-repeat: no-repeat;
    background-position: center;
    &:hover {
      background-image: url(../Img/Icons/ButtonIcons/favorite-hover-icon.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-color: $darkBlue;
    }
  }

  &--favourite-selected {
    background-image: url(../Img/Icons/ButtonIcons/favorite-icon.svg);
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    &:hover {
      background-image: url(../Img/Icons/ButtonIcons/favorite-hover-icon.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-color: $darkBlue;
    }

    &::after {
      content: 'x';
      position: absolute;
      width: 10px;
      height: 10px;
      color: red;
      right: -2px;
      bottom: 3px;
    }
  }
  &--delete {
    background-image: url(../Img/Icons/ButtonIcons/delete-icon.svg);
    background-repeat: no-repeat;
    background-position: center;
    &:hover {
      background-image: url(../Img/Icons/ButtonIcons/delete-hover-icon.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-color: $orange;
    }
  }
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  font-size: 15px;
  // -webkit-text-fill-color: #969090;
  caret-color: #fff;
  // box-shadow: 0 0 0px 1000px rgba(255, 255, 255, 0) inset;
  transition: background-color 5000s ease-in-out 0s;
  background-color: $lightCream;
}
:-internal-autofill-previewed {
  font-size: 15px !important;
}

.Toastify {
  &__close-button {
    color: #717171 !important;
  }
  &__toast {
    color: rgb(68, 67, 67);
    font-family: $fontLato;
    text-align: center;
    @include rwd(481px) {
      border-radius: 5px !important;
    }
    &--error {
      background: $lightCream !important;
      border-left: 8px solid #e74c3c;
    }
    &--success {
      background: $lightCream !important;
      border-left: 8px solid #07bc0c;
    }
  }
}
.mg-top-60 {
  margin-top: 60px;
}
.tooltip > .tooltip-inner {
  background-color: $darkBlue;
}
.tooltip {
  font-weight: 400 !important;
  letter-spacing: 1px;
  font-family: $fontLato;
  background-color: $darkBlue !important;
  padding: 3px 10px !important;
}
.tooltip.place-top {
  &:after {
    border-top-color: $darkBlue !important;
    border-top-width: 8px !important;
  }
}
.tooltip.place-right {
  &:after {
    border-right-color: $darkBlue !important;
    border-right-width: 6px !important;
  }
}

.tooltip > .tooltip-arrow {
  background-color: $darkBlue;
}

.breadcrumbs {
  display: inline-block;
  line-height: 1;
  &__arrow {
    margin-right: 5px;
    padding-top: 2px;
    img {
      transform: rotate(-180deg);
      max-width: 7px;
    }
  }
  &__element {
    margin-right: 5px;
    text-transform: uppercase;
    font-size: 12px;
    font-family: $fontLato;
    font-weight: 900;
    letter-spacing: 1px;
    text-decoration: none;
    color: $darkBlue;
    &:not(:last-of-type) {
      color: rgba($darkBlue, 0.36);
    }
  }
  &__inner {
    display: flex;
    align-items: center;
  }
}

.externalPagination {
  display: inline-block;
  &__inner {
    display: flex;
    align-items: center;
  }
  &__title {
    font-size: 13px;
    line-height: 19px;
    color: rgba($darkBlue, 0.52);
    font-family: $fontLato;
    font-weight: 600;
    margin-right: 15px;
  }
  &__sizeSelect {
    position: relative;
    &--value {
      border-radius: 6px;
      border: 1px solid #565d70;
      padding: 3px 6px;
      font-weight: 600;
      font-size: 13px;
      line-height: 1;
      color: $darkBlue;
      font-family: $fontLato;
      cursor: pointer;
      user-select: none;
    }
    &--options {
      position: absolute;
      border: 1px solid rgba($darkBlue, 0.34);
      border-radius: 6px;
      top: 21px;
      left: 0;
      right: 0;
      width: 100%;
      text-align: center;
      cursor: pointer;
      z-index: 999;
      background-color: #fff;
      font-weight: 600;
      font-size: 13px;
      line-height: 19px;
      color: $darkBlue;
      font-family: $fontLato;
    }
  }
  &__page {
    font-weight: 600;
    font-size: 13px;
    line-height: 1;
    padding: 4px;
    color: rgba($darkBlue, 0.34);
    font-family: $fontLato;
    cursor: pointer;
    &:hover,
    &--active {
      color: $darkBlue;
    }
  }
  &__pages {
    margin-left: 10px;
  }
}

.internal-users-log,
.internal-users-group {
  padding: 35px 15px 0 0;
  max-width: 640px;
  max-height: calc(100vh - 34px);
  overflow-x: auto;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__button {
    margin: 40px 0;
  }
  &__date{
    margin-top: 10px;
    font-size: 13px;
    line-height: 19px;
    font-family: "Lato", sans-serif;
    text-align: left;
    font-weight: 700;
    color: #222d5c;
    display: flex;
    flex-direction: column;
    max-width: 230px;
  }
}

h2 {
  &.main-subtitle,
  & > span {
    font-size: 17px;
    color: #222d5c;
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    text-transform: uppercase;
  }
  &.flex-subtitle {
    display: flex;
    // justify-content: space-between;
    width: 100%;
    align-items: flex-start;
    flex-direction: column;
    button {
      margin-top: 10px;
    }
  }
}
.react-select {
  .react-select__option {
    font-size: 13px;
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    color: rgba(26, 26, 26, 0.65);
  }
}
