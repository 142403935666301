@import url(https://fonts.googleapis.com/css?family=Lato:300,400,700,900&display=swap);
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

* {
  box-sizing: border-box; }

input[type='range'] {
  -webkit-appearance: none;
  /* Hides the slider so that custom slider can be made */
  width: 100%;
  /* Specific width is required for Firefox. */
  background: transparent;
  /* Otherwise white in Chrome */ }

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none; }

input[type='range']:focus {
  outline: none;
  /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */ }

input[type='range']::-ms-track {
  width: 100%;
  cursor: pointer;
  /* Hides the slider so custom styles can be added */
  background: transparent;
  border-color: transparent;
  color: transparent; }

/* Small devices */
/* Medium devices */
/* Large devices */
/* Extra large devices */
/* Extra large devices */
/* Custom breakpoint */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

* {
  box-sizing: border-box; }

input[type='range'] {
  -webkit-appearance: none;
  /* Hides the slider so that custom slider can be made */
  width: 100%;
  /* Specific width is required for Firefox. */
  background: transparent;
  /* Otherwise white in Chrome */ }

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none; }

input[type='range']:focus {
  outline: none;
  /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */ }

input[type='range']::-ms-track {
  width: 100%;
  cursor: pointer;
  /* Hides the slider so custom styles can be added */
  background: transparent;
  border-color: transparent;
  color: transparent; }

.newclassaddtestcicd {
  display: none; }

html {
  scroll-behavior: smooth; }

body {
  line-height: 1.5;
  font-size: 16px; }

.email {
  text-decoration: none;
  color: #000000; }

.pointer {
  cursor: pointer; }

#remindAt {
  padding-bottom: 30px; }

.btn {
  background: none;
  outline: none;
  border: 0;
  border-radius: 5px;
  display: block;
  font-weight: 400;
  font-size: 17px;
  padding: 0 30px;
  text-decoration: none;
  font-family: "Lato", sans-serif;
  text-align: center;
  cursor: pointer; }
  .btn--default {
    height: 48px;
    line-height: 48px;
    background-color: #222d5c;
    font-weight: black;
    color: #fff;
    border-radius: 3px;
    width: 100%;
    text-transform: uppercase;
    font-size: 13px; }
    .btn--default:hover {
      background-color: #3d3d69; }
  .btn--main {
    width: auto;
    border-radius: 6px;
    font-weight: 900;
    letter-spacing: 1px; }
  .btn--add-client {
    height: 30px;
    line-height: 30px;
    background-color: #222d5c;
    font-weight: black;
    color: #fff;
    border-radius: 3px;
    width: 130px;
    text-transform: uppercase;
    font-size: 12px;
    display: block;
    margin-top: 20px; }
    .btn--add-client:hover {
      background-color: #3d3d69; }
  .btn--cancel {
    height: 48px;
    background-color: #fff;
    font-weight: 700;
    color: #222d5c;
    width: 150px;
    text-transform: uppercase;
    border: 2px solid #f3efec;
    border-radius: 3px;
    font-size: 13px;
    letter-spacing: 1px; }
    .btn--cancel:hover {
      background-color: #f3f3f3; }
  .btn--confirm {
    height: 48px;
    line-height: 48px;
    background-color: #ff5800;
    font-weight: 700;
    color: #ffffff;
    width: 150px;
    text-transform: uppercase;
    font-size: 13px;
    border-radius: 3px;
    letter-spacing: 1px; }
    .btn--confirm:hover {
      background-color: #e04f02; }
  .btn--login {
    font-size: 17px; }
  .btn--navigation {
    height: 37px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-size: 13px;
    margin: 0;
    line-height: 37px;
    padding: 0 5px;
    box-sizing: content-box;
    background-color: unset;
    color: #222d5c;
    font-weight: bold; }
    .btn--navigation span {
      margin-right: 7px; }
    .btn--navigation:hover {
      font-weight: 900; }
  .btn--center {
    margin-left: auto;
    margin-right: auto; }
  .btn--delete {
    background-color: #e80000;
    color: #fff; }
    .btn--delete:hover {
      background-color: #d80202; }
  .btn--disabled {
    color: #e4e4e4;
    cursor: not-allowed; }
    .btn--disabled:hover {
      background-color: #fff;
      box-shadow: 0px 1px 5px rgba(156, 156, 156, 0.32); }

.btn-action {
  background: none;
  outline: none;
  border: 0;
  border-radius: 6px;
  display: block;
  background-color: #f3f3f3;
  width: 25px;
  height: 25px;
  float: left;
  margin-right: 8px;
  cursor: pointer; }
  .btn-action--reply {
    background-image: url(/static/media/reply-icon.7d8287ea.svg);
    background-repeat: no-repeat;
    background-position: center; }
    .btn-action--reply:hover {
      background-image: url(/static/media/reply-hover-icon.67f5c0c6.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-color: #222d5c; }
  .btn-action--date {
    background-image: url(/static/media/date-icon.c8c3cda7.svg);
    background-repeat: no-repeat;
    background-position: center; }
    .btn-action--date:hover {
      background-image: url(/static/media/date-hover-icon.e192e5ba.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-color: #222d5c; }
  .btn-action--folder {
    background-image: url(/static/media/folder-icon.e3e8e226.svg);
    background-repeat: no-repeat;
    background-position: center; }
    .btn-action--folder:hover {
      background-image: url(/static/media/folder-hover-icon.0be35b64.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-color: #222d5c; }
  .btn-action--cancel {
    background-image: url(/static/media/cancel-icon.e8205662.svg);
    background-repeat: no-repeat;
    background-position: center; }
    .btn-action--cancel:hover {
      background-image: url(/static/media/cancel-hover-icon.63bd881b.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-color: #ff5800; }
  .btn-action--edit {
    background-image: url(/static/media/edit-icon.bb029e46.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 16px; }
    .btn-action--edit:hover {
      background-image: url(/static/media/edit-hover-icon.cb066285.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-color: #222d5c; }
  .btn-action--search {
    background-image: url(/static/media/search-icon.06dc9219.svg);
    background-repeat: no-repeat;
    background-position: center; }
    .btn-action--search:hover {
      background-image: url(/static/media/search-hover-icon.083af10e.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-color: #222d5c; }
  .btn-action--open {
    background-image: url(/static/media/open-icon.edb156ca.svg);
    background-repeat: no-repeat;
    background-position: center; }
    .btn-action--open:hover {
      background-image: url(/static/media/open-hover-icon.8cd5ac02.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-color: #222d5c; }
  .btn-action--favourite {
    background-image: url(/static/media/favorite-icon.567170f4.svg);
    background-repeat: no-repeat;
    background-position: center; }
    .btn-action--favourite:hover {
      background-image: url(/static/media/favorite-hover-icon.2949e1cb.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-color: #222d5c; }
  .btn-action--favourite-selected {
    background-image: url(/static/media/favorite-icon.567170f4.svg);
    background-repeat: no-repeat;
    background-position: center;
    position: relative; }
    .btn-action--favourite-selected:hover {
      background-image: url(/static/media/favorite-hover-icon.2949e1cb.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-color: #222d5c; }
    .btn-action--favourite-selected::after {
      content: 'x';
      position: absolute;
      width: 10px;
      height: 10px;
      color: red;
      right: -2px;
      bottom: 3px; }
  .btn-action--delete {
    background-image: url(/static/media/delete-icon.d586a0e4.svg);
    background-repeat: no-repeat;
    background-position: center; }
    .btn-action--delete:hover {
      background-image: url(/static/media/delete-hover-icon.f902fa67.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-color: #ff5800; }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  font-size: 15px;
  caret-color: #fff;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
  background-color: #f3f3f3; }

:-internal-autofill-previewed {
  font-size: 15px !important; }

.Toastify__close-button {
  color: #717171 !important; }

.Toastify__toast {
  color: #444343;
  font-family: "Lato", sans-serif;
  text-align: center; }
  @media (min-width: 481px) {
    .Toastify__toast {
      border-radius: 5px !important; } }
  .Toastify__toast--error {
    background: #f3f3f3 !important;
    border-left: 8px solid #e74c3c; }
  .Toastify__toast--success {
    background: #f3f3f3 !important;
    border-left: 8px solid #07bc0c; }

.mg-top-60 {
  margin-top: 60px; }

.tooltip > .tooltip-inner {
  background-color: #222d5c; }

.tooltip {
  font-weight: 400 !important;
  letter-spacing: 1px;
  font-family: "Lato", sans-serif;
  background-color: #222d5c !important;
  padding: 3px 10px !important; }

.tooltip.place-top:after {
  border-top-color: #222d5c !important;
  border-top-width: 8px !important; }

.tooltip.place-right:after {
  border-right-color: #222d5c !important;
  border-right-width: 6px !important; }

.tooltip > .tooltip-arrow {
  background-color: #222d5c; }

.breadcrumbs {
  display: inline-block;
  line-height: 1; }
  .breadcrumbs__arrow {
    margin-right: 5px;
    padding-top: 2px; }
    .breadcrumbs__arrow img {
      transform: rotate(-180deg);
      max-width: 7px; }
  .breadcrumbs__element {
    margin-right: 5px;
    text-transform: uppercase;
    font-size: 12px;
    font-family: "Lato", sans-serif;
    font-weight: 900;
    letter-spacing: 1px;
    text-decoration: none;
    color: #222d5c; }
    .breadcrumbs__element:not(:last-of-type) {
      color: rgba(34, 45, 92, 0.36); }
  .breadcrumbs__inner {
    display: flex;
    align-items: center; }

.externalPagination {
  display: inline-block; }
  .externalPagination__inner {
    display: flex;
    align-items: center; }
  .externalPagination__title {
    font-size: 13px;
    line-height: 19px;
    color: rgba(34, 45, 92, 0.52);
    font-family: "Lato", sans-serif;
    font-weight: 600;
    margin-right: 15px; }
  .externalPagination__sizeSelect {
    position: relative; }
    .externalPagination__sizeSelect--value {
      border-radius: 6px;
      border: 1px solid #565d70;
      padding: 3px 6px;
      font-weight: 600;
      font-size: 13px;
      line-height: 1;
      color: #222d5c;
      font-family: "Lato", sans-serif;
      cursor: pointer;
      -webkit-user-select: none;
          -ms-user-select: none;
              user-select: none; }
    .externalPagination__sizeSelect--options {
      position: absolute;
      border: 1px solid rgba(34, 45, 92, 0.34);
      border-radius: 6px;
      top: 21px;
      left: 0;
      right: 0;
      width: 100%;
      text-align: center;
      cursor: pointer;
      z-index: 999;
      background-color: #fff;
      font-weight: 600;
      font-size: 13px;
      line-height: 19px;
      color: #222d5c;
      font-family: "Lato", sans-serif; }
  .externalPagination__page {
    font-weight: 600;
    font-size: 13px;
    line-height: 1;
    padding: 4px;
    color: rgba(34, 45, 92, 0.34);
    font-family: "Lato", sans-serif;
    cursor: pointer; }
    .externalPagination__page:hover, .externalPagination__page--active {
      color: #222d5c; }
  .externalPagination__pages {
    margin-left: 10px; }

.internal-users-log,
.internal-users-group {
  padding: 35px 15px 0 0;
  max-width: 640px;
  max-height: calc(100vh - 34px);
  overflow-x: auto; }
  .internal-users-log__header,
  .internal-users-group__header {
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .internal-users-log__button,
  .internal-users-group__button {
    margin: 40px 0; }
  .internal-users-log__date,
  .internal-users-group__date {
    margin-top: 10px;
    font-size: 13px;
    line-height: 19px;
    font-family: "Lato", sans-serif;
    text-align: left;
    font-weight: 700;
    color: #222d5c;
    display: flex;
    flex-direction: column;
    max-width: 230px; }

h2.main-subtitle,
h2 > span {
  font-size: 17px;
  color: #222d5c;
  font-family: 'Lato', sans-serif;
  font-weight: bold;
  text-transform: uppercase; }

h2.flex-subtitle {
  display: flex;
  width: 100%;
  align-items: flex-start;
  flex-direction: column; }
  h2.flex-subtitle button {
    margin-top: 10px; }

.react-select .react-select__option {
  font-size: 13px;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  color: rgba(26, 26, 26, 0.65); }

.ReactTable {
  position: relative;
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(0, 0, 0, 0.1); }

.ReactTable * {
  box-sizing: border-box; }

.ReactTable .rt-table {
  flex: auto 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  border-collapse: collapse;
  overflow: auto; }

.ReactTable .rt-thead {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.ReactTable .rt-thead.-headerGroups {
  background: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.05); }

.ReactTable .rt-thead.-filters {
  border-bottom: 1px solid rgba(0, 0, 0, 0.22); }

.ReactTable .rt-thead.-filters input,
.ReactTable .rt-thead.-filters select {
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  padding: 5px 7px;
  font-size: inherit;
  border-radius: 3px;
  font-weight: normal;
  outline-width: 0; }

.ReactTable .rt-thead.-filters .rt-th {
  border-right: 1px solid rgba(0, 0, 0, 0.02); }

.ReactTable .rt-thead.-header {
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.15); }

.ReactTable .rt-thead .rt-tr {
  text-align: center; }

.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td {
  padding: 5px 5px;
  line-height: normal;
  position: relative;
  border-right: 1px solid rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  box-shadow: inset 0 0 0 0 transparent; }

.ReactTable .rt-thead .rt-th.-sort-asc,
.ReactTable .rt-thead .rt-td.-sort-asc {
  box-shadow: inset 0 3px 0 0 rgba(0, 0, 0, 0.6); }

.ReactTable .rt-thead .rt-th.-sort-desc,
.ReactTable .rt-thead .rt-td.-sort-desc {
  box-shadow: inset 0 -3px 0 0 rgba(0, 0, 0, 0.6); }

.ReactTable .rt-thead .rt-th.-cursor-pointer,
.ReactTable .rt-thead .rt-td.-cursor-pointer {
  cursor: pointer; }

.ReactTable .rt-thead .rt-th:last-child,
.ReactTable .rt-thead .rt-td:last-child {
  border-right: 0; }

.ReactTable .rt-thead .rt-th:focus {
  outline-width: 0; }

.ReactTable .rt-thead .rt-resizable-header {
  overflow: visible; }

.ReactTable .rt-thead .rt-resizable-header:last-child {
  overflow: hidden; }

.ReactTable .rt-thead .rt-resizable-header-content {
  overflow: hidden;
  text-overflow: ellipsis; }

.ReactTable .rt-thead .rt-header-pivot {
  border-right-color: #f7f7f7; }

.ReactTable .rt-thead .rt-header-pivot:after,
.ReactTable .rt-thead .rt-header-pivot:before {
  left: 100%;
  top: 50%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none; }

.ReactTable .rt-thead .rt-header-pivot:after {
  border-color: rgba(255, 255, 255, 0);
  border-left-color: #fff;
  border-width: 8px;
  margin-top: -8px; }

.ReactTable .rt-thead .rt-header-pivot:before {
  border-color: rgba(102, 102, 102, 0);
  border-left-color: #f7f7f7;
  border-width: 10px;
  margin-top: -10px; }

.ReactTable .rt-tbody {
  flex: 99999 1 auto;
  display: flex;
  flex-direction: column;
  overflow: auto; }

.ReactTable .rt-tbody .rt-tr-group {
  border-bottom: solid 1px rgba(0, 0, 0, 0.22); }
  .ReactTable .rt-tbody .rt-tr-group .rt-td {
    position: relative;
    height: 100%; }
    .ReactTable .rt-tbody .rt-tr-group .rt-td:not(:last-child)::after {
      content: '';
      position: absolute;
      right: 0;
      z-index: 100;
      top: 5px;
      width: 1px;
      height: 87%;
      background: rgba(112, 112, 112, 0.22); }

.ReactTable .rt-tbody .rt-tr-group:last-child {
  border-bottom: 0; }

.ReactTable .rt-tbody .rt-td {
  border-right: 1px solid rgba(0, 0, 0, 0.02); }

.ReactTable .rt-tbody .rt-td:last-child {
  border-right: 0; }

.ReactTable .rt-tbody .rt-expandable {
  cursor: pointer;
  text-overflow: clip; }

.ReactTable .rt-tr-group {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch; }

.ReactTable .rt-tr {
  flex: 1 0 auto;
  display: inline-flex; }

.ReactTable .rt-th,
.ReactTable .rt-td {
  flex: 1 0;
  padding: 7px 5px;
  transition: 0.3s ease;
  transition-property: width, min-width, padding, opacity; }

.ReactTable .rt-th.-hidden,
.ReactTable .rt-td.-hidden {
  width: 0 !important;
  min-width: 0 !important;
  padding: 0 !important;
  border: 0 !important;
  opacity: 0 !important; }

.ReactTable .rt-expander {
  display: inline-block;
  position: relative;
  margin: 0;
  color: transparent;
  margin: 0 10px; }

.ReactTable .rt-expander:after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-90deg);
  border-left: 5.04px solid transparent;
  border-right: 5.04px solid transparent;
  border-top: 7px solid rgba(0, 0, 0, 0.8);
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  cursor: pointer; }

.ReactTable .rt-expander.-open:after {
  transform: translate(-50%, -50%) rotate(0); }

.ReactTable .rt-resizer {
  display: inline-block;
  position: absolute;
  width: 36px;
  top: 0;
  bottom: 0;
  right: -18px;
  cursor: col-resize;
  z-index: 10; }

.ReactTable .rt-tfoot {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15); }

.ReactTable .rt-tfoot .rt-td {
  border-right: 1px solid rgba(0, 0, 0, 0.05); }

.ReactTable .rt-tfoot .rt-td:last-child {
  border-right: 0; }

.ReactTable.-striped .rt-tr.-odd {
  background: rgba(0, 0, 0, 0.03); }

.ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
  background: rgba(0, 0, 0, 0.05); }

.ReactTable .-pagination {
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
  padding: 3px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  border-top: 2px solid rgba(0, 0, 0, 0.1); }

.ReactTable .-pagination input,
.ReactTable .-pagination select {
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  padding: 5px 7px;
  font-size: inherit;
  border-radius: 3px;
  font-weight: normal;
  outline-width: 0; }

.ReactTable .-pagination .-btn {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: block;
  width: 100%;
  height: 100%;
  border: 0;
  border-radius: 3px;
  padding: 6px;
  font-size: 1em;
  color: rgba(0, 0, 0, 0.6);
  background: rgba(0, 0, 0, 0.1);
  transition: all 0.1s ease;
  cursor: pointer;
  outline-width: 0; }

.ReactTable .-pagination .-btn[disabled] {
  opacity: 0.5;
  cursor: default; }

.ReactTable .-pagination .-btn:not([disabled]):hover {
  background: rgba(0, 0, 0, 0.3);
  color: #fff; }

.ReactTable .-pagination .-previous,
.ReactTable .-pagination .-next {
  flex: 1 1;
  text-align: center; }

.ReactTable .-pagination .-center {
  flex: 1.5 1;
  text-align: center;
  margin-bottom: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around; }

.ReactTable .-pagination .-pageInfo {
  display: inline-block;
  margin: 3px 10px;
  white-space: nowrap; }

.ReactTable .-pagination .-pageJump {
  display: inline-block; }

.ReactTable .-pagination .-pageJump input {
  width: 70px;
  text-align: center; }

.ReactTable .-pagination .-pageSizeOptions {
  margin: 3px 10px; }

.ReactTable .rt-noData {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.8);
  transition: all 0.3s ease;
  z-index: 1;
  pointer-events: none;
  padding: 13px;
  margin-top: 30px;
  color: rgba(0, 0, 0, 0.5); }

.ReactTable .-loading {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  transition: all 0.3s ease;
  z-index: -1;
  opacity: 0;
  pointer-events: none; }

.ReactTable .-loading > div {
  position: absolute;
  display: block;
  text-align: center;
  width: 100%;
  top: 50%;
  left: 0;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.6);
  transform: translateY(-52%);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94); }

.ReactTable .-loading.-active {
  opacity: 1;
  z-index: 2;
  pointer-events: all; }

.ReactTable .-loading.-active > div {
  transform: translateY(50%); }

.ReactTable .rt-resizing .rt-th,
.ReactTable .rt-resizing .rt-td {
  transition: none !important;
  cursor: col-resize;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.ReactTable.regular-table {
  border: 0px; }
  .ReactTable.regular-table .rt-thead.-header {
    box-shadow: none; }
    .ReactTable.regular-table .rt-thead.-header .rt-th {
      padding: 7px 10px;
      border-right: 0px; }
    .ReactTable.regular-table .rt-thead.-header .rt-resizable-header-content {
      font-size: 13px;
      line-height: 19px;
      font-family: "Lato", sans-serif;
      text-align: left;
      font-weight: 700;
      color: #222d5c; }
  .ReactTable.regular-table .rt-thead.-filters {
    padding-bottom: 10px; }
  .ReactTable.regular-table .rt-tbody .rt-td {
    font-size: 13px;
    line-height: 19px;
    font-family: "Lato", sans-serif;
    text-align: left;
    font-weight: 700;
    color: #222d5c;
    padding: 7px 10px; }
  .ReactTable.regular-table__case-descriptions .rt-thead {
    border-bottom: 1px solid #cfcfcf;
    background: rgba(112, 112, 112, 0.23); }

.ReactTable .rt-thead.-filters input.table-filter-input {
  display: block;
  color: #f3f3f3;
  border-radius: 6px;
  background: #f3f3f3 0% 0% no-repeat padding-box;
  max-width: 130px;
  font-size: 13px;
  line-height: 19px;
  font-family: "Lato", sans-serif;
  text-align: left;
  font-weight: 700;
  color: #222d5c; }

.filter--light-grey {
  background-color: #F3F3F3; }

.table {
  border-spacing: 0px 12px; }
  .table--limits {
    max-width: 956px; }
    .table--limits td {
      border-bottom: 0 !important; }
    .table--limits th span {
      padding-left: 6px; }
    .table--limits tbody:before {
      display: none; }

.limits td:not(:last-child)::after {
  height: 100%; }

.limits--pointer {
  cursor: pointer; }

.limits--MAXIMUM td, .limits--WARNING td {
  padding: 8px 8px 8px 16px !important;
  position: relative; }
  .limits--MAXIMUM td:before, .limits--WARNING td:before {
    content: '';
    position: absolute;
    bottom: -6px;
    left: 0;
    width: 100%;
    border-bottom: 1px solid #7070704a !important; }
  .limits--MAXIMUM td:first-child, .limits--WARNING td:first-child {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px; }
  .limits--MAXIMUM td:last-child, .limits--WARNING td:last-child {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px; }

.limits--MAXIMUM td {
  background-color: rgba(255, 88, 0, 0.25); }

.limits--WARNING td {
  background-color: rgba(234, 170, 5, 0.16); }

.main-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative; }

.home-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .home-page h1 {
    font-size: 40px; }
  .home-page__button {
    border: 0;
    width: 150px;
    font-size: 20px;
    height: 40px;
    color: #fff;
    background-color: #a7a5a5; }

.login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; }
  .login__container {
    font-family: "Lato", sans-serif;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column; }
  .login__header {
    height: 320px;
    position: relative;
    background-color: #f3efec; }
    @media (min-width: 756px) {
      .login__header {
        height: 390px; } }
  .login__logo {
    position: absolute;
    margin-top: 41px;
    margin-left: 17px; }
    @media (min-width: 756px) {
      .login__logo {
        margin-left: 45px; } }
    .login__logo img {
      width: 44px;
      height: 45px; }
  .login__logo-entire {
    position: absolute;
    bottom: 40px;
    width: 100%;
    text-align: center;
    display: block; }
    .login__logo-entire img {
      width: 213px;
      height: 67px; }
  .login__form-container {
    width: 100%;
    padding: 50px 0 0 0;
    display: flex;
    justify-content: center; }
  .login__form {
    width: 310px; }
    .login__form .form__field input {
      height: 50px;
      padding: 5px 10px;
      border-radius: 5px; }
    .login__form .btn {
      margin-top: 30px; }
  .login__forget-password {
    float: right;
    margin: -10px 0 50px 0;
    font-size: 15px;
    font-weight: 400;
    color: #222d5c; }

.preloader {
  position: fixed;
  z-index: 110;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; }
  .preloader__container {
    position: relative;
    width: 60px;
    height: 80px; }
  .preloader__container-inner {
    height: 50px;
    width: 50px;
    animation: spin 1.4s infinite linear both; }
  .preloader__container-inner--lg {
    width: 40px;
    height: 40px;
    animation: spin 1s infinite linear both;
    animation-delay: 0.4; }
  .preloader__container-inner--md {
    width: 30px;
    height: 30px;
    animation: spin 0.9s infinite linear both;
    animation-delay: 0.8; }
  .preloader__container-inner--sm {
    width: 20px;
    height: 20px;
    animation: spin 0.6s infinite linear both;
    animation-delay: 1.2; }
  .preloader__container-inner, .preloader__container-inner--sm, .preloader__container-inner--xs, .preloader__container-inner--md, .preloader__container-inner--lg {
    position: absolute;
    top: 60%;
    left: 50%;
    border-radius: 50%;
    border-left: 3px solid #222d5c;
    border-top: 3px solid transparent;
    border-right: 3px solid #222d5c;
    border-bottom: 3px solid transparent; }
    .preloader__container-inner--sm, .preloader__container-inner--sm--sm, .preloader__container-inner--xs--sm, .preloader__container-inner--md--sm, .preloader__container-inner--lg--sm {
      height: 30px;
      width: 30px;
      border-width: 2px; }
  .preloader--sm {
    position: absolute;
    z-index: 20;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.6); }

@keyframes spinBackwards {
  from {
    transform: translate(-50%, -50%) rotate(360deg); }
  to {
    transform: translate(-50%, -50%) rotate(0deg); } }

@keyframes spin {
  from {
    transform: translate(-50%, -50%) rotate(0deg); }
  to {
    transform: translate(-50%, -50%) rotate(360deg); } }

.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px; }

.lds-dual-ring:after {
  content: ' ';
  display: block;
  width: 24px;
  height: 24px;
  margin: 8px;
  border-radius: 50%;
  border: 3px solid #222d5c;
  border-color: #222d5c transparent #222d5c transparent;
  animation: lds-dual-ring 1.2s linear infinite; }

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.dashboard {
  min-height: 100vh;
  display: flex;
  flex-direction: column; }
  .dashboard__content {
    margin-top: 124px;
    position: relative; }
  @media (min-width: 1200px) {
    .dashboard {
      padding: 17px;
      flex-direction: row; }
      .dashboard__content {
        margin: 0 0 0 400px;
        width: calc(100% - 397px);
        overflow-y: auto; } }

.navigation {
  background-color: #f3efec; }
  .navigation__menu {
    height: 104px;
    display: flex;
    transition: height 400ms ease;
    justify-content: flex-end;
    background-color: #f3efec;
    position: fixed;
    width: 100%;
    z-index: 101; }
    @media (min-width: 1200px) {
      .navigation__menu {
        position: fixed;
        width: 380px;
        margin-right: 17px; } }
    .navigation__menu--desktop {
      display: none;
      font-family: "Lato", sans-serif;
      font-size: 17px;
      height: 50px;
      cursor: pointer; }
      .navigation__menu--desktop a {
        color: #222;
        text-decoration: none;
        margin: 0 5px; }
      .navigation__menu--desktop li {
        color: #222;
        padding: 15px;
        height: 50px; }
        .navigation__menu--desktop li:hover {
          background-color: #fbfbfb;
          box-shadow: 0px 1px 5px rgba(156, 156, 156, 0.5);
          border-radius: 5px; }
      @media (min-width: 1200px) {
        .navigation__menu--desktop {
          display: flex; } }
    .navigation__menu--mobile {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      height: 100%;
      font-family: "Lato", sans-serif;
      font-size: 17px;
      padding: 110px 20px 20px 20px;
      cursor: pointer; }
      @media (min-width: 1200px) {
        .navigation__menu--mobile {
          padding: 110px 30px 20px 30px; } }
      .navigation__menu--mobile a {
        text-decoration: none;
        width: 100%;
        display: flex;
        align-items: center; }
      .navigation__menu--mobile .logout {
        color: rgba(0, 0, 0, 0.3);
        padding: 15px;
        height: 50px;
        display: flex;
        align-items: center;
        margin-top: 10px; }
        .navigation__menu--mobile .logout img {
          margin-left: 10px; }
        @media (min-width: 1200px) {
          .navigation__menu--mobile .logout {
            display: none; } }
      .navigation__menu--mobile .limits {
        display: flex;
        align-items: center;
        width: 100%;
        cursor: auto !important; }
        .navigation__menu--mobile .limits a {
          width: auto;
          margin-right: 15px;
          width: 50px; }
        .navigation__menu--mobile .limits .list:hover:after {
          display: none; }
        .navigation__menu--mobile .limits .counter {
          width: 20px;
          height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 13px;
          margin: 0 5px;
          border-radius: 10px; }
          .navigation__menu--mobile .limits .counter--orange {
            background-color: #EAAA05;
            color: #1E1E1E; }
          .navigation__menu--mobile .limits .counter--red {
            background-color: #FF5800;
            color: #fff; }
      .navigation__menu--mobile .list {
        position: relative;
        width: 100%;
        border-radius: 6px;
        height: 30px;
        line-height: 30px;
        font-size: 13px;
        font-family: "Lato", sans-serif;
        font-weight: 400;
        letter-spacing: 1.3px;
        text-transform: uppercase;
        display: flex;
        color: #222d5c; }
        .navigation__menu--mobile .list:hover {
          font-weight: 900; }
          .navigation__menu--mobile .list:hover:after {
            content: '';
            position: absolute;
            width: 20px;
            right: 0px;
            height: 29px;
            background-image: url(/static/media/active-menu-icon.61040c2d.svg);
            background-repeat: no-repeat;
            background-position: center; }
        @media (min-width: 1200px) {
          .navigation__menu--mobile .list {
            background-color: unset;
            font-weight: 400; } }
        .navigation__menu--mobile .list__icon {
          position: relative;
          margin-right: 10px;
          transform: translateY(-50%);
          top: 50%;
          width: 18px; }
        .navigation__menu--mobile .list__counter {
          color: #222d5c;
          width: 50px; }
        .navigation__menu--mobile .list--settings:before {
          content: '';
          width: 20px;
          height: 20px;
          margin-top: 5px;
          margin-right: 10px;
          background-image: url(/static/media/settings-icon.826ac98f.svg);
          background-repeat: no-repeat;
          background-position: center;
          background-size: 16px; }
        .navigation__menu--mobile .list--settings:hover:before {
          content: '';
          width: 20px;
          height: 20px;
          margin-top: 5px;
          margin-right: 10px;
          background-image: url(/static/media/settings-hover-icon.f610f862.svg);
          background-repeat: no-repeat;
          background-position: center;
          background-size: 16px; }
    .navigation__menu--expanded {
      height: 100%;
      overflow-y: auto;
      overflow-x: hidden; }
      @media (min-width: 1200px) {
        .navigation__menu--expanded {
          height: calc(100vh - 34px); } }
    .navigation__menu--selected-nav-item .list {
      font-weight: 900; }
      .navigation__menu--selected-nav-item .list:after {
        content: '';
        position: absolute;
        width: 20px;
        right: 0px;
        height: 29px;
        background-image: url(/static/media/active-menu-icon.61040c2d.svg);
        background-repeat: no-repeat;
        background-position: center; }
      .navigation__menu--selected-nav-item .list--settings:before {
        background-image: url(/static/media/settings-hover-icon.f610f862.svg); }
  .navigation__menu-hamburger {
    --color: #222d5c;
    width: 36px;
    height: 36px;
    padding: 0;
    right: 32px;
    top: 32px;
    outline: none;
    position: absolute;
    border: none;
    background: none;
    cursor: pointer;
    -webkit-appearence: none;
    -webkit-tap-highlight-color: transparent; }
    .navigation__menu-hamburger svg {
      width: 84px;
      height: 64px;
      top: -6px;
      left: -14px;
      stroke: var(--color);
      stroke-width: 1px;
      stroke-linecap: round;
      stroke-linejoin: round;
      fill: none;
      display: block;
      position: absolute; }
      .navigation__menu-hamburger svg path {
        transition: stroke-dasharray 0.85s ease 0s, stroke-dashoffset 0.85s ease 0s;
        transition: stroke-dasharray var(--duration, 0.85s) var(--easing, ease) var(--delay, 0s), stroke-dashoffset var(--duration, 0.85s) var(--easing, ease) var(--delay, 0s);
        stroke-dasharray: 26px 100px;
        stroke-dasharray: var(--array-1, 26px) var(--array-2, 100px);
        stroke-dashoffset: 126px;
        stroke-dashoffset: var(--offset, 126px);
        transform: translateZ(0); }
        .navigation__menu-hamburger svg path:nth-child(2) {
          --duration: 0.7s;
          --easing: ease-in;
          --offset: 100px;
          --array-2: 74px; }
        .navigation__menu-hamburger svg path:nth-child(3) {
          --offset: 133px;
          --array-2: 107px; }
    .navigation__menu-hamburger--active svg path {
      --offset: 57px; }
      .navigation__menu-hamburger--active svg path:nth-child(1), .navigation__menu-hamburger--active svg path:nth-child(3) {
        --delay: 0.15s;
        --easing: cubic-bezier(0.2, 0.4, 0.2, 1.1); }
      .navigation__menu-hamburger--active svg path:nth-child(2) {
        --duration: 0.4s;
        --offset: 2px;
        --array-1: 1px; }
      .navigation__menu-hamburger--active svg path:nth-child(3) {
        --offset: 58px; }
    @media (min-width: 1200px) {
      .navigation__menu-hamburger {
        display: none; } }
  .navigation__logout {
    right: 25px;
    top: 32px;
    outline: none;
    position: absolute;
    border: none;
    background: none;
    cursor: pointer;
    -webkit-appearence: none;
    -webkit-tap-highlight-color: transparent;
    font-family: "Lato", sans-serif;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.3);
    height: 50px;
    display: flex;
    align-items: center;
    margin-top: 3px; }
    .navigation__logout img {
      margin-left: 10px;
      width: 17px;
      height: 15px; }
    @media screen and (max-width: 1200px) {
      .navigation__logout {
        display: none; } }
  .navigation__menu-logo {
    position: absolute;
    top: 39px;
    left: 31px;
    display: flex;
    align-items: center; }
    .navigation__menu-logo img {
      width: 40px;
      height: 41px; }
      .navigation__menu-logo img:hover {
        cursor: pointer; }
    .navigation__menu-logo span {
      margin-left: 18px;
      font-family: "Lato", sans-serif;
      font-weight: bold;
      font-size: 12px;
      color: #222d5c; }
  .navigation__container {
    padding-top: 104px; }
  .navigation__list-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-bottom: 2px solid rgba(34, 45, 92, 0.19);
    margin-bottom: 10px; }
    .navigation__list-title .list-title {
      border-radius: 6px;
      display: flex;
      height: 37px;
      line-height: 37px;
      font-size: 13px;
      font-family: "Lato", sans-serif;
      font-weight: bold;
      letter-spacing: 1.3px;
      text-transform: uppercase;
      color: rgba(34, 45, 92, 0.28); }
      .navigation__list-title .list-title__counter {
        color: #222d5c;
        width: 50px; }
  .navigation__form {
    width: 100%;
    margin-bottom: 15px; }
    .navigation__form .btn {
      margin: 15px 0; }
    .navigation__form .form__field {
      margin-bottom: 14px; }

.reminders-list {
  position: absolute;
  width: 100%;
  height: 100%; }
  .reminders-list__buttons {
    display: flex; }
    @media (max-width: 756px) {
      .reminders-list__buttons {
        flex-direction: column; } }
    .reminders-list__buttons a:first-child {
      margin-right: 20px; }
      @media (max-width: 756px) {
        .reminders-list__buttons a:first-child {
          margin-bottom: 20px; } }
    .reminders-list__buttons span {
      margin-right: 5px; }
  .reminders-list__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0 15px 17px; }
    @media (max-width: 756px) {
      .reminders-list__header {
        flex-direction: column;
        align-items: flex-start; } }
  .reminders-list__title h2 {
    font-size: 17px;
    color: #222d5c;
    font-family: "Lato", sans-serif;
    font-weight: bold;
    text-transform: uppercase; }
  @media (max-width: 756px) {
    .reminders-list__title {
      margin-bottom: 20px; } }
  .reminders-list__table {
    overflow-x: auto; }
    @media screen and (max-width: 1200px) {
      .reminders-list__table table {
        table-layout: unset; }
        .reminders-list__table table tbody .actions {
          display: flex;
          flex-direction: row;
          align-items: center; }
        .reminders-list__table table tbody tr {
          height: unset; }
        .reminders-list__table table tbody td {
          vertical-align: middle;
          white-space: nowrap;
          padding: 2px 15px 10px 15px; } }
  .reminders-list a {
    text-decoration: none;
    color: inherit; }

.remind {
  font-weight: normal; }
  .remind strong {
    font-weight: bold; }

.add-reminder {
  position: relative;
  width: 100%;
  padding: 30px;
  background-color: #f3efec;
  margin: 0 auto; }
  @media (min-width: 501px) {
    .add-reminder {
      width: 380px; } }
  @media (min-width: 1200px) {
    .add-reminder {
      height: calc(100vh - 34px);
      width: 380px;
      margin: unset; } }
  .add-reminder .respond-text {
    font-family: "Lato", sans-serif;
    font-size: 14px; }
    .add-reminder .respond-text strong {
      font-weight: 700; }
  .add-reminder__title {
    display: flex;
    align-items: center; }
    .add-reminder__title img {
      margin-top: 8px;
      margin-right: 15px;
      cursor: pointer; }
    .add-reminder__title h2 {
      font-size: 17px;
      color: #222d5c;
      font-family: "Lato", sans-serif;
      font-weight: bold;
      text-transform: uppercase; }
  .add-reminder__form-container {
    margin-top: 90px;
    border-top: 2px solid #222d5c;
    height: calc(100% - 107px); }
  .add-reminder__form {
    margin-top: 20px;
    position: relative;
    height: calc(100% - 17px); }
    .add-reminder__form .btn {
      margin-top: 60px; }
      @media (min-width: 1200px) {
        .add-reminder__form .btn {
          position: absolute;
          bottom: 10px; } }
    .add-reminder__form .form__field input,
    .add-reminder__form .form__field textarea {
      border-radius: 5px; }

.add-customer,
.narrow-section {
  position: relative;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  padding: 30px;
  background-color: #f3efec;
  margin: 0 auto; }
  @media (min-width: 1200px) {
    .add-customer,
    .narrow-section {
      height: calc(100vh - 34px);
      margin: unset; } }
  .add-customer__title,
  .narrow-section__title {
    display: flex;
    align-items: center; }
    .add-customer__title img,
    .narrow-section__title img {
      margin-top: 8px;
      margin-right: 15px;
      cursor: pointer; }
    .add-customer__title h2,
    .narrow-section__title h2 {
      font-size: 17px;
      color: #222d5c;
      font-family: "Lato", sans-serif;
      font-weight: bold;
      text-transform: uppercase; }
  .add-customer__form-container,
  .narrow-section__form-container {
    margin-top: 90px;
    border-top: 2px solid #222d5c;
    height: calc(100% - 107px);
    display: flex;
    justify-content: space-around; }
  .add-customer__added-clients,
  .narrow-section__added-clients {
    min-width: -webkit-max-content;
    min-width: -moz-max-content;
    min-width: max-content; }
  .add-customer__form,
  .narrow-section__form {
    margin-top: 20px;
    width: 300px;
    position: relative;
    height: calc(100% - 17px);
    overflow-y: scroll; }
    .add-customer__form .btn,
    .narrow-section__form .btn {
      margin-top: 60px; }
      @media (min-width: 1200px) {
        .add-customer__form .btn,
        .narrow-section__form .btn {
          position: absolute; } }
    .add-customer__form .form__field input,
    .add-customer__form .form__field textarea,
    .narrow-section__form .form__field input,
    .narrow-section__form .form__field textarea {
      border-radius: 5px; }
    .add-customer__form--type-list,
    .narrow-section__form--type-list {
      font-family: "Lato", sans-serif;
      font-size: 15px;
      color: rgba(26, 26, 26, 0.85); }
    .add-customer__form--padding,
    .narrow-section__form--padding {
      padding: 0 10px; }
  .add-customer--details,
  .narrow-section--details {
    max-width: 360px; }
    .add-customer--details__inner,
    .narrow-section--details__inner {
      height: calc(100% - 30px);
      position: relative; }
    .add-customer--details__wrapper,
    .narrow-section--details__wrapper {
      display: flex; }
    .add-customer--details__summary,
    .narrow-section--details__summary {
      max-width: 630px;
      width: 100%;
      padding: 30px; }
      .add-customer--details__summary .btn--addNewCustomer,
      .narrow-section--details__summary .btn--addNewCustomer {
        margin-top: 60px; }
    .add-customer--details__userInfo,
    .narrow-section--details__userInfo {
      display: flex;
      width: 100%;
      font-size: 13px;
      line-height: 1.5;
      font-weight: bold;
      padding: 10px 0;
      font-family: 'Lato', sans-serif; }
      .add-customer--details__userInfo--name,
      .narrow-section--details__userInfo--name {
        width: 33%;
        padding: 5px 15px 5px 0;
        border-right: 1px solid rgba(112, 112, 112, 0.22); }
      .add-customer--details__userInfo--phone,
      .narrow-section--details__userInfo--phone {
        width: 22%;
        padding: 5px 15px;
        border-right: 1px solid rgba(112, 112, 112, 0.22); }
      .add-customer--details__userInfo--email,
      .narrow-section--details__userInfo--email {
        width: 35%;
        padding: 5px 15px; }
      .add-customer--details__userInfo--actions,
      .narrow-section--details__userInfo--actions {
        cursor: pointer;
        padding: 5px 0 5px 15px;
        width: 10%;
        text-align: right;
        display: flex;
        justify-content: flex-end; }
        .add-customer--details__userInfo--actions .btn-action--edit,
        .narrow-section--details__userInfo--actions .btn-action--edit {
          margin-right: 0; }
    .add-customer--details__actions,
    .narrow-section--details__actions {
      text-align: right; }
    .add-customer--details__addCustomerButton,
    .narrow-section--details__addCustomerButton {
      display: inline-block; }
  .add-customer__subtitle,
  .narrow-section__subtitle {
    font-size: 13px;
    line-height: 1.5;
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    letter-spacing: 1.3px;
    text-transform: uppercase;
    color: #222d5c;
    margin: 15px 0; }
    .add-customer__subtitle--margintop,
    .narrow-section__subtitle--margintop {
      margin-top: 60px;
      display: inline-block; }
  .add-customer__newClient,
  .narrow-section__newClient {
    display: flex;
    font-size: 13px;
    line-height: 1.5;
    font-weight: bold;
    width: 100%;
    padding: 10px 0;
    font-family: 'Lato', sans-serif;
    border-bottom: 1px solid rgba(112, 112, 112, 0.22); }
    .add-customer__newClient--name,
    .narrow-section__newClient--name {
      width: 33%;
      padding: 5px 15px 5px 0;
      border-right: 1px solid rgba(112, 112, 112, 0.22); }
    .add-customer__newClient--casesType,
    .narrow-section__newClient--casesType {
      padding: 5px 15px; }
    .add-customer__newClient--actions,
    .narrow-section__newClient--actions {
      margin-left: auto;
      padding: 5px 0 5px 15px;
      text-align: right; }
      .add-customer__newClient--actions .btn-action--delete,
      .narrow-section__newClient--actions .btn-action--delete {
        margin-right: 0; }

button:disabled {
  cursor: not-allowed; }

.add-customer__form .react-select__menu {
  height: auto;
  padding-bottom: 20px; }

.customers-list {
  position: absolute;
  width: 100%;
  height: 100%; }
  .customers-list__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0 15px 17px; }
  .customers-list__title {
    display: flex;
    align-items: center; }
    .customers-list__title img {
      margin-top: 6px;
      margin-right: 15px;
      cursor: pointer; }
    .customers-list__title h2 {
      font-size: 17px;
      color: #222d5c;
      font-family: "Lato", sans-serif;
      font-weight: bold;
      text-transform: uppercase; }
  .customers-list__table {
    max-height: calc(100% - 130px);
    height: 100%;
    overflow-x: scroll;
    overflow-y: auto; }
    .customers-list__table thead th {
      position: -webkit-sticky;
      position: sticky;
      top: 0; }
    .customers-list__table th {
      z-index: 101;
      background: #fff; }
    .customers-list__table table {
      table-layout: unset; }
      .customers-list__table table tbody .actions {
        display: flex;
        flex-direction: row;
        align-items: center; }
      .customers-list__table table tbody tr {
        height: unset; }
      .customers-list__table table tbody td {
        vertical-align: middle;
        white-space: nowrap;
        padding: 2px 15px 10px 15px; }
        .customers-list__table table tbody td a {
          text-decoration: none;
          color: #344594; }

.favorite-customers-list {
  position: absolute;
  width: 100%;
  height: 100%; }
  .favorite-customers-list__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0 15px 17px; }
  .favorite-customers-list__title {
    display: flex;
    align-items: center; }
    .favorite-customers-list__title img {
      margin-top: 7px;
      margin-right: 15px;
      cursor: pointer; }
    .favorite-customers-list__title h2 {
      font-size: 17px;
      color: #222d5c;
      font-family: "Lato", sans-serif;
      font-weight: bold;
      text-transform: uppercase; }
  .favorite-customers-list__table {
    max-height: calc(100% - 130px);
    height: 100%;
    overflow-x: scroll;
    overflow-y: auto; }
    .favorite-customers-list__table thead th {
      position: -webkit-sticky;
      position: sticky;
      top: 0; }
    .favorite-customers-list__table th {
      z-index: 101;
      background: #fff; }
    .favorite-customers-list__table table {
      table-layout: unset; }
      .favorite-customers-list__table table tbody .actions {
        display: flex;
        flex-direction: row;
        align-items: center; }
      .favorite-customers-list__table table tbody tr {
        height: unset; }
      .favorite-customers-list__table table tbody td {
        vertical-align: middle;
        white-space: nowrap;
        padding: 2px 15px 10px 15px; }

table {
  border-collapse: separate;
  border-spacing: 0 0.4rem;
  width: 100%;
  padding: 0 15px;
  table-layout: fixed;
  font-family: "Lato", sans-serif; }
  table thead {
    height: 35px;
    line-height: 20px;
    border-radius: 3px; }
    table thead tr th {
      border-bottom: 1px solid #707070;
      font-size: 12px;
      font-weight: 600;
      color: #2e2e2e;
      padding: 0 3px 13px 10px;
      text-align: left;
      white-space: nowrap; }
      table thead tr th input {
        background-color: #b2b2b2;
        border-radius: 6px;
        border: none;
        height: 22px;
        width: 100%;
        margin-top: 10px;
        padding: 0 7px;
        line-height: 22px;
        outline: none; }
    table thead tr .actions {
      width: 130px; }
    table thead tr .remind {
      width: 235px; }
  table tbody:before {
    content: '';
    margin-top: 5px;
    display: block; }
  table tbody .colored {
    background-color: #f8bebe; }
  table tbody tr {
    height: 40px;
    line-height: 15px;
    border-radius: 4px; }
    table tbody tr:last-of-type td {
      border: none; }
    table tbody tr .action-icons {
      display: flex;
      justify-content: center;
      align-items: center;
      height: inherit; }
      table tbody tr .action-icons .fa-headphones-alt {
        font-size: 1.5rem;
        color: #bab7b7;
        margin-right: 10px; }
        table tbody tr .action-icons .fa-headphones-alt:hover {
          color: #727ce5; }
      table tbody tr .action-icons .fa-heart {
        font-size: 1.5rem;
        color: #bab7b7;
        margin-right: 10px; }
      table tbody tr .action-icons .fa-download {
        font-size: 1.2rem;
        color: #bab7b7; }
  table tbody td {
    padding: 2px 15px 25px 15px;
    border-bottom: 1px solid rgba(112, 112, 112, 0.22);
    position: relative;
    font-size: 12px;
    font-weight: bold;
    color: #2e2e2e; }
    table tbody td:not(:last-child):after {
      content: '';
      position: absolute;
      right: 0;
      z-index: 100;
      top: 0;
      width: 1px;
      height: 85%;
      /* or 100px */
      background: rgba(112, 112, 112, 0.22); }
      table tbody td:not(:last-child):after:last-of-type {
        display: none; }
  table tbody .actions {
    vertical-align: -webkit-baseline-middle; }
  table tbody .case {
    color: #222d5c; }
  table tbody .remind {
    font-weight: 400;
    line-height: 19px; }
    table tbody .remind strong {
      font-weight: 900; }

.filled-thead tr {
  border-radius: 6px; }
  .filled-thead tr th {
    background-color: #f3f3f3;
    padding: 8px 15px;
    border-bottom: 0px; }
    .filled-thead tr th:first-of-type {
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px; }
    .filled-thead tr th:last-of-type {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px; }

.files__table--content--name {
  margin-left: 17px; }

.time-trial-list {
  position: absolute;
  width: 100%;
  height: 100%; }
  .time-trial-list__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0 15px 17px;
    flex-direction: column; }
    @media (min-width: 601px) {
      .time-trial-list__header {
        flex-direction: row; } }
  .time-trial-list__summary {
    padding: 10px 0 20px 20px; }
    .time-trial-list__summary p {
      font-size: 14px;
      font-weight: 500;
      font-family: "Lato", sans-serif;
      margin-bottom: 6px;
      color: #2e2e2e; }
  .time-trial-list__export {
    padding: 10px 0 20px 20px;
    display: flex;
    align-items: flex-start; }
    .time-trial-list__export div {
      margin-right: 20px;
      font-size: 14px;
      font-weight: 500;
      font-family: "Lato", sans-serif;
      color: #afafaf;
      cursor: pointer; }
      .time-trial-list__export div:hover {
        color: #2e2e2e; }
    .time-trial-list__export--active {
      color: #2e2e2e !important; }
  .time-trial-list__calendar {
    max-width: 230px; }
    .time-trial-list__calendar:hover label {
      color: inherit; }
    .time-trial-list__calendar .react-daterange-picker__inputGroup {
      margin: unset; }
    .time-trial-list__calendar .react-daterange-picker__calendar {
      z-index: 101; }
      .time-trial-list__calendar .react-daterange-picker__calendar .react-calendar div {
        margin-right: unset; }
        .time-trial-list__calendar .react-daterange-picker__calendar .react-calendar div:hover {
          color: inherit; }
  .time-trial-list__title {
    display: flex;
    align-items: center; }
    .time-trial-list__title img {
      margin-top: 6px;
      margin-right: 15px;
      cursor: pointer; }
    .time-trial-list__title h2 {
      font-size: 17px;
      color: #222d5c;
      font-family: "Lato", sans-serif;
      font-weight: bold;
      text-transform: uppercase; }
  .time-trial-list__right {
    display: flex;
    align-items: center;
    text-decoration: none; }
    @media (min-width: 601px) {
      .time-trial-list__right {
        flex-direction: row; } }
  .time-trial-list__form-search {
    width: 890px;
    margin-right: 30px; }
    .time-trial-list__form-search .form__field input {
      padding: 5px 10px;
      height: 25px; }
    .time-trial-list__form-search .form__field input::-webkit-input-placeholder {
      color: rgba(46, 46, 46, 0.29); }
    .time-trial-list__form-search .form__field input:-ms-input-placeholder {
      color: rgba(46, 46, 46, 0.29); }
    .time-trial-list__form-search .form__field input::placeholder {
      color: rgba(46, 46, 46, 0.29); }
  .time-trial-list__table {
    overflow-x: auto; }
    @media screen and (max-width: 1200px) {
      .time-trial-list__table table {
        table-layout: unset; }
        .time-trial-list__table table tbody .actions {
          display: flex;
          flex-direction: row;
          align-items: center; }
        .time-trial-list__table table tbody tr {
          height: unset; }
        .time-trial-list__table table tbody td {
          vertical-align: middle;
          white-space: nowrap;
          padding: 2px 15px 10px 15px; } }

.add-time-trial {
  position: relative;
  width: 100%;
  padding: 30px;
  background-color: #f3efec;
  margin: 0 auto;
  overflow-y: auto;
  transition: 0.3s; }
  @media (min-width: 501px) {
    .add-time-trial {
      width: 380px; } }
  @media (min-width: 1200px) {
    .add-time-trial {
      height: calc(100vh - 34px);
      width: 380px;
      margin: unset; } }
  .add-time-trial--rolled-up {
    width: 40px;
    padding: 0;
    transition: 0.3s;
    margin-left: -20px; }
  .add-time-trial__title {
    display: flex;
    align-items: center; }
    .add-time-trial__title img {
      margin-top: 8px;
      margin-right: 15px;
      cursor: pointer; }
    .add-time-trial__title h2 {
      font-size: 17px;
      color: #222d5c;
      font-family: "Lato", sans-serif;
      font-weight: bold;
      text-transform: uppercase; }
  .add-time-trial__form-container {
    margin-top: 90px;
    border-top: 2px solid #222d5c;
    height: calc(100% - 122px); }
  .add-time-trial__form {
    margin-top: 20px;
    position: relative;
    height: calc(100% - 17px); }
    .add-time-trial__form .btn {
      margin-top: 60px; }
      @media (min-width: 1200px) {
        .add-time-trial__form .btn {
          margin-bottom: 30px;
          margin-bottom: 30px; } }
    .add-time-trial__form .form__field input,
    .add-time-trial__form .form__field textarea {
      border-radius: 5px; }

.cases-list {
  height: 100%;
  display: flex;
  overflow: hidden; }
  .cases-list__link {
    text-decoration: none;
    color: #344594; }
  .cases-list__summary {
    position: relative;
    display: flex;
    padding-left: 25px;
    background-color: #f3efec;
    margin: 0 auto;
    transition: 0.3s; }
    @media (min-width: 501px) {
      .cases-list__summary {
        max-width: 380px; } }
    @media (min-width: 1200px) {
      .cases-list__summary {
        height: calc(100vh - 34px);
        max-width: 430px;
        margin: unset;
        overflow-y: scroll; } }
    .cases-list__summary--rolled-up {
      max-width: 40px;
      padding: 0;
      margin-left: -20px;
      overflow: hidden;
      right: -5px; }
    .cases-list__summary--content {
      width: 410px;
      max-width: 100%;
      padding-top: 25px;
      transition: 0.3s;
      padding-right: 30px; }
    .cases-list__summary__title {
      display: flex;
      align-items: center; }
      .cases-list__summary__title img {
        margin-top: 8px;
        margin-right: 15px;
        cursor: pointer; }
      .cases-list__summary__title h2 {
        font-size: 17px;
        color: #222d5c;
        font-family: "Lato", sans-serif;
        font-weight: bold;
        text-transform: uppercase; }
    .cases-list__summary__company {
      margin-top: 20px;
      font-size: 17px;
      color: #222d5c;
      font-family: "Lato", sans-serif;
      font-weight: bold; }
    .cases-list__summary__form-container {
      margin-top: 90px;
      border-top: 2px solid #222d5c;
      height: calc(100% - 107px); }
    .cases-list__summary__form {
      margin-top: 20px;
      position: relative;
      height: calc(100% - 17px); }
      .cases-list__summary__form .btn {
        margin-top: 60px; }
        @media (min-width: 1200px) {
          .cases-list__summary__form .btn {
            position: absolute;
            bottom: 10px; } }
      .cases-list__summary__form .form__field input,
      .cases-list__summary__form .form__field textarea {
        border-radius: 5px; }
  .cases-list__short-summary {
    display: flex;
    justify-content: space-between;
    padding: 25px 0;
    line-height: 20px;
    align-items: flex-start; }
    .cases-list__short-summary p {
      color: rgba(0, 0, 0, 0.4);
      font-size: 13px;
      font-weight: 400;
      font-family: "Lato", sans-serif; }
      .cases-list__short-summary p:first-of-type {
        line-height: 8px; }
    .cases-list__short-summary span {
      margin-left: 10px; }
    .cases-list__short-summary--in-progress {
      color: #ff5800;
      font-size: 25px;
      font-weight: 900; }
    .cases-list__short-summary--done {
      color: #222d5c;
      font-size: 25px;
      font-weight: 900; }
  .cases-list__cases-status {
    display: flex;
    border-bottom: 2px solid rgba(34, 45, 92, 0.19);
    justify-content: flex-end;
    margin-bottom: 10px; }
    .cases-list__cases-status p {
      font-size: 12px;
      font-family: "Lato", sans-serif;
      font-weight: 600;
      margin-left: 12px;
      margin-bottom: 5px; }
    .cases-list__cases-status--in-progress {
      color: #ff5800; }
    .cases-list__cases-status--all {
      color: black; }
    .cases-list__cases-status--done {
      color: rgba(0, 0, 0, 0.4); }
  .cases-list__cases-types {
    color: #222d5c;
    font-size: 13px;
    font-weight: 400;
    font-family: "Lato", sans-serif;
    text-transform: uppercase;
    letter-spacing: 1.4px; }
    .cases-list__cases-types ul li {
      padding-top: 10px;
      display: flex;
      justify-content: space-between;
      padding-bottom: 10px;
      position: relative; }
      .cases-list__cases-types ul li:hover {
        border-bottom: 1px solid rgba(34, 45, 92, 0.22);
        font-weight: 900;
        padding-left: 25px;
        cursor: pointer; }
        .cases-list__cases-types ul li:hover:before {
          content: '';
          position: absolute;
          width: 20px;
          left: 0px;
          top: 49%;
          transform: translateY(-50%);
          height: 40px;
          background-image: url(/static/media/active-menu-icon.61040c2d.svg);
          background-repeat: no-repeat;
          background-position: center; }
      .cases-list__cases-types ul li span {
        font-weight: 700; }
    .cases-list__cases-types--selected {
      border-bottom: 1px solid rgba(34, 45, 92, 0.22);
      font-weight: 900;
      padding-left: 25px;
      position: relative; }
      .cases-list__cases-types--selected:before {
        content: '';
        position: absolute;
        width: 20px;
        left: 0px;
        top: 49%;
        transform: translateY(-50%);
        height: 40px;
        background-image: url(/static/media/active-menu-icon.61040c2d.svg);
        background-repeat: no-repeat;
        background-position: center; }
    .cases-list__cases-types .container {
      display: flex; }
    .cases-list__cases-types--element {
      text-align: left !important; }
    .cases-list__cases-types--in-progress {
      color: #ff5800;
      padding-right: 22px; }
    .cases-list__cases-types--all {
      color: #222d5c;
      padding-right: 15px; }
    .cases-list__cases-types--done {
      color: rgba(34, 45, 92, 0.38); }
    .cases-list__cases-types--in-progress, .cases-list__cases-types--all, .cases-list__cases-types--done {
      width: 60px;
      display: block;
      text-align: right; }
  .cases-list__expander {
    width: 25px;
    padding: 0 1px 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: #eae7e4;
    margin-left: 20px;
    border-left: 1px solid rgba(0, 0, 0, 0.053);
    transition: 0.3s;
    height: 100%;
    position: absolute;
    right: 0; }
    .cases-list__expander:hover {
      background-color: #dbd7d4;
      transition: 0.3s; }
    .cases-list__expander--search-engine {
      margin-left: 0;
      border-left: none;
      left: 0;
      right: auto;
      position: relative; }
    .cases-list__expander--rolled-up {
      position: absolute;
      transition: 0.3s; }
    .cases-list__expander--icon-right {
      transform: rotate(180deg); }
  .cases-list__container {
    width: calc(100% - 350px);
    transition: 0.3s; }
    .cases-list__container--full-width {
      width: 100%; }
    .cases-list__container--limits {
      width: 100%;
      margin-left: 40px;
      margin-top: 30px; }
      @media (max-width: 1200px) {
        .cases-list__container--limits {
          margin-right: 40px; } }
      @media (max-width: 756px) {
        .cases-list__container--limits {
          margin-left: 0px; } }
    .cases-list__container--rolled-up {
      width: calc(100% - 60px); }
    .cases-list__container--without-scroll {
      max-height: calc(100vh - 35px);
      overflow: hidden; }
      .cases-list__container--without-scroll .cases-list__table {
        height: calc(100% - 129px);
        padding-bottom: 20px;
        overflow: scroll; }
  .cases-list__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 15px 0 15px 17px; }
  .cases-list__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%; }
    .cases-list__title .btn--default {
      width: unset; }
    .cases-list__title img {
      margin-top: 6px;
      margin-right: 15px;
      cursor: pointer; }
    .cases-list__title h2 {
      font-size: 17px;
      color: #222d5c;
      font-family: "Lato", sans-serif;
      font-weight: bold;
      text-transform: uppercase; }
  .cases-list__table {
    height: calc(100% - 55px);
    overflow-x: scroll;
    overflow-y: auto; }
    .cases-list__table--search-engine {
      height: calc(100% - 100px); }
    .cases-list__table thead th {
      position: -webkit-sticky;
      position: sticky;
      top: 0; }
    .cases-list__table th {
      z-index: 101;
      background: #fff; }
    .cases-list__table table {
      table-layout: unset; }
      .cases-list__table table tbody .actions {
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative; }
        .cases-list__table table tbody .actions--electronicOnly::before {
          position: absolute;
          content: '';
          width: 12px;
          height: 12px;
          border-radius: 100%;
          left: -5px;
          background: #75a941; }
      .cases-list__table table tbody tr {
        height: unset; }
      .cases-list__table table tbody td {
        vertical-align: middle;
        white-space: nowrap;
        padding: 2px 15px 10px 15px; }

.settings-list {
  position: relative;
  width: 100%;
  padding: 15px;
  margin: 0 auto; }
  @media (min-width: 501px) {
    .settings-list {
      width: 450px; } }
  @media (min-width: 1200px) {
    .settings-list {
      height: calc(100vh - 34px);
      width: 450px;
      margin: unset; } }
  .settings-list__title {
    display: flex;
    align-items: center; }
    .settings-list__title img {
      margin-top: 7px;
      margin-right: 15px;
      cursor: pointer; }
    .settings-list__title h2 {
      font-size: 17px;
      color: #222d5c;
      font-family: "Lato", sans-serif;
      font-weight: bold;
      text-transform: uppercase; }
  .settings-list__links-container {
    padding-left: 25px;
    height: calc(100% - 107px); }
    .settings-list__links-container ul {
      margin: 30px 0 50px 0; }
      .settings-list__links-container ul a {
        line-height: 40px;
        font-size: 13px;
        font-family: "Lato", sans-serif;
        font-weight: 400;
        letter-spacing: 1.3px;
        text-transform: uppercase;
        color: #222d5c;
        display: flex;
        position: relative;
        text-decoration: none; }
        .settings-list__links-container ul a:hover {
          font-weight: 900;
          cursor: pointer; }
          .settings-list__links-container ul a:hover:before {
            content: '';
            position: absolute;
            width: 15px;
            top: 48%;
            transform: translateY(-50%);
            right: -10px;
            height: 29px;
            background-image: url(/static/media/active-menu-icon.61040c2d.svg);
            background-repeat: no-repeat;
            background-position: center; }

.external-users-list {
  position: absolute;
  width: 100%;
  height: 100%; }
  .external-users-list__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0 15px 17px; }
  .external-users-list__title {
    display: flex;
    align-items: center; }
    .external-users-list__title img {
      margin-top: 7px;
      margin-right: 15px;
      cursor: pointer; }
    .external-users-list__title h2 {
      font-size: 17px;
      color: #222d5c;
      font-family: "Lato", sans-serif;
      font-weight: bold;
      text-transform: uppercase; }
  .external-users-list__table {
    overflow-x: auto; }
    .external-users-list__table table {
      table-layout: unset; }
      .external-users-list__table table tbody .actions {
        display: flex;
        flex-direction: row;
        align-items: center; }
      .external-users-list__table table tbody tr {
        height: unset; }
      .external-users-list__table table tbody td {
        vertical-align: middle;
        white-space: nowrap;
        padding: 2px 15px 10px 15px; }

.internal-users-list {
  position: absolute;
  width: 100%;
  height: 100%; }
  .internal-users-list__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0 15px 17px; }
  .internal-users-list__title {
    display: flex;
    align-items: center; }
    .internal-users-list__title img {
      margin-top: 7px;
      margin-right: 15px;
      cursor: pointer; }
    .internal-users-list__title h2 {
      font-size: 17px;
      color: #222d5c;
      font-family: "Lato", sans-serif;
      font-weight: bold;
      text-transform: uppercase; }
  .internal-users-list__table {
    overflow-x: auto; }
    .internal-users-list__table table {
      table-layout: unset; }
      .internal-users-list__table table tbody .actions {
        display: flex;
        flex-direction: row;
        align-items: center; }
      .internal-users-list__table table tbody tr {
        height: unset; }
      .internal-users-list__table table tbody td {
        vertical-align: middle;
        white-space: nowrap;
        padding: 2px 15px 10px 15px; }

.pagination {
  font-family: "Lato", sans-serif;
  font-weight: bold;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center; }
  .pagination .react-select {
    width: 55px; }
    .pagination .react-select__control {
      border-radius: 6px;
      border: 1px solid #565d70;
      min-height: 25px !important;
      height: 25px;
      color: #2e2e2e; }
    .pagination .react-select__menu {
      text-align: center;
      padding: 0;
      z-index: 150; }
    .pagination .react-select__option {
      padding: 2px 0;
      font-size: 13px;
      color: #2e2e2e; }
    .pagination .react-select__value-container {
      padding: 0px 10px;
      height: 25px;
      font-size: 13px; }
    .pagination .react-select__indicator-separator {
      display: none; }
    .pagination .react-select__dropdown-indicator {
      padding: 0px 5px; }
      .pagination .react-select__dropdown-indicator img {
        height: 10px;
        margin-top: unset;
        margin-right: unset; }
    .pagination .react-select__clear-indicator {
      display: none; }
  .pagination p {
    font-size: 13px;
    color: rgba(46, 46, 46, 0.52);
    margin-right: 12px; }
  .pagination__list {
    margin-left: 12px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding: 10px 0; }
  .pagination__list-element {
    min-width: 20px;
    height: 28px;
    color: rgba(46, 46, 46, 0.34);
    display: flex;
    margin: 0 2px;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    cursor: pointer; }
    .pagination__list-element--active, .pagination__list-element:hover {
      color: #2e2e2e; }
    .pagination__list-element--disabled {
      color: #e2e2e2; }
      .pagination__list-element--disabled:hover {
        background-color: #fff;
        color: #e2e2e2; }

.confirmation-popup {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 120;
  background: rgba(0, 0, 0, 0.45);
  top: 0;
  left: 0;
  right: 0; }
  .confirmation-popup__inner {
    position: relative;
    animation: showPopup 0.19s ease-in-out;
    width: 380px;
    border-radius: 2px;
    margin: 0 10px;
    background: #fff;
    box-shadow: 0px 2px 5px rgba(56, 20, 20, 0.22); }
    .confirmation-popup__inner .fa-times {
      position: absolute;
      top: 10px;
      right: 10px;
      font-size: 25px;
      color: #827d7d; }
    .confirmation-popup__inner--hidePopup {
      animation: hidePopup 0.19s ease-in-out; }
  .confirmation-popup__content {
    text-align: left; }
    .confirmation-popup__content h2 {
      font-family: "Lato", sans-serif;
      text-transform: uppercase;
      font-weight: 700;
      letter-spacing: 1px;
      color: #222d5c;
      font-size: 17px;
      background-color: #f3efec;
      padding: 15px 20px; }
    .confirmation-popup__content h6 {
      font-family: "Lato", sans-serif;
      padding: 30px 0;
      color: #2e2e2e;
      font-weight: 700;
      font-size: 13px;
      margin: 0 20px;
      border-bottom: 2px solid rgba(34, 45, 92, 0.19); }
  .confirmation-popup__buttons {
    display: flex;
    justify-content: space-between;
    margin: 15px 20px; }

@keyframes showPopup {
  0% {
    transform: translateY(-400px);
    opacity: 0; }
  100% {
    transform: translateY(0px);
    opacity: 1; } }

@keyframes hidePopup {
  0% {
    transform: translateY(0px);
    opacity: 1; }
  100% {
    transform: translateY(-400px);
    opacity: 0; } }

.caseDetails {
  padding: 15px;
  max-width: 1100px; }
  .caseDetails .breadcrumbs {
    margin-bottom: 40px; }
  .caseDetails h2 {
    margin-bottom: 30px; }
  .caseDetails__summary, .caseDetails__status {
    margin-bottom: 70px; }
  .caseDetails__summary {
    max-width: 600px; }
    .caseDetails__summary--electronicOnly {
      display: flex;
      align-items: center;
      font-weight: 700;
      font-size: 13px;
      line-height: 16px;
      font-family: Lato, sans-serif;
      margin-left: 245px; }
      .caseDetails__summary--electronicOnly .switch {
        margin-left: 16px; }
  .caseDetails__summary--actions {
    display: block;
    height: 40px; }
  .caseDetails__print {
    position: absolute;
    right: 0;
    width: 200px; }
  .caseDetails__files {
    position: absolute;
    right: 220px;
    width: 200px; }
  .caseDetails .rt-noData {
    display: none; }

.summary .summaryBlock {
  display: flex;
  width: 100%;
  border-bottom: 1px solid rgba(112, 112, 112, 0.43);
  font-size: 13px;
  line-height: 19px;
  font-family: "Lato", sans-serif;
  text-align: left;
  font-weight: 700; }
  .summary .summaryBlock--pointer {
    cursor: pointer; }
  .summary .summaryBlock--hyperlink .summaryBlock__value {
    color: #0000ee; }
  .summary .summaryBlock__title {
    padding: 10px 0 10px 10px;
    flex: 0 1 225px;
    background-color: #f3f3f3; }
    .summary .summaryBlock__title p {
      border-right: 1px solid rgba(112, 112, 112, 0.23);
      padding-right: 10px;
      width: 225px; }
  .summary .summaryBlock__value {
    padding: 10px;
    flex-grow: 1;
    color: #000000;
    text-decoration: none; }
    .summary .summaryBlock__value a {
      color: #000000;
      text-decoration: none; }

.addCaseRemainder .form__field--date {
  margin-left: 0; }

.fake_field {
  display: flex;
  justify-content: space-between;
  font-family: 'Lato', sans-serif; }
  .fake_field__label {
    font-weight: 400;
    color: #1a1a1a;
    font-size: 12px;
    pointer-events: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0px 0 5px 2px; }
  .fake_field__value {
    color: #222d5c;
    font-size: 12px;
    font-weight: bold; }

.case-status {
  display: flex;
  justify-content: space-between; }

.closeCase {
  background-color: #f3f3f3;
  padding: 5px 18px;
  font-size: 10px;
  text-transform: uppercase;
  line-height: 14px;
  cursor: pointer;
  border-radius: 5px; }

.remainderReplies {
  margin-left: -20px;
  margin-right: -20px;
  overflow: auto; }

.remainderReply {
  font-family: 'Lato', sans-serif;
  font-size: 13px;
  line-height: 1.5;
  background-color: #f3f3f3;
  border-radius: 6px;
  padding: 15px;
  margin-bottom: 30px;
  width: 100%; }
  .remainderReply__client {
    font-weight: 600;
    color: rgba(46, 46, 46, 0.29);
    margin-bottom: 5px; }
  .remainderReply__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px; }
  .remainderReply__caseNo {
    font-weight: 900;
    color: #222d5c; }
  .remainderReply__date {
    font-weight: 700; }

.form--inner {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 120px); }
  .form--inner .form__field {
    width: 100%; }

.statistics {
  position: absolute;
  width: 100%;
  height: 100%; }
  .statistics__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0 15px 17px;
    flex-direction: column; }
    @media (min-width: 601px) {
      .statistics__header {
        flex-direction: row; } }
  .statistics__title {
    display: flex;
    align-items: center; }
    .statistics__title img {
      margin-top: 6px;
      margin-right: 15px;
      cursor: pointer; }
    .statistics__title h2 {
      font-size: 17px;
      color: #222d5c;
      font-family: "Lato", sans-serif;
      font-weight: bold;
      text-transform: uppercase; }
  .statistics__options {
    display: flex; }
  .statistics__toggle {
    margin-top: 30px;
    display: flex; }
  .statistics__block {
    font-family: "Lato", sans-serif;
    display: flex;
    font-size: 14px;
    align-items: center;
    justify-content: center;
    width: 170px;
    height: 37px;
    border-radius: 6px;
    background-color: #f3f3f3;
    text-transform: uppercase;
    color: rgba(34, 45, 92, 0.49);
    font-weight: bold;
    cursor: pointer; }
    .statistics__block:first-child {
      margin-right: -5px; }
    .statistics__block--active {
      background-color: #222d5c;
      color: white;
      z-index: 10; }
  .statistics .externalPagination {
    margin-top: 20px; }
  .statistics__table {
    margin-top: 30px; }

.search-engine {
  display: flex;
  height: 100%; }
  .search-engine__pagination {
    padding: 10px 20px; }

.files {
  padding: 20px;
  font-family: 'Lato', sans-serif; }
  .files__title {
    margin: 50px 0 50px 0; }
  .files__table {
    width: auto; }
    .files__table--contentWrapper {
      display: flex;
      grid-gap: 30px;
      gap: 30px;
      margin-top: 10px; }
    .files__table--header {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-bottom: 10px;
      margin-top: 20px;
      border-bottom: 1px solid lightgray; }
      .files__table--header .data {
        display: flex; }
      .files__table--header .head {
        display: flex;
        margin-left: 30px;
        width: 260px; }
      .files__table--header .data img, .files__table--header .head img {
        margin-left: 10px; }
    .files__table--content .btn-action {
      float: none;
      margin-bottom: 10px; }
    .files__table--content .file {
      margin-bottom: 11px;
      cursor: pointer;
      width: 260px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: 10px; }
    .files__table--content--file {
      display: flex; }
    .files__table--content .data {
      margin-right: 16px; }
    .files__table--goBack {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 290px;
      height: 50px; }
      .files__table--goBack .lds-dual-ring {
        position: relative;
        top: 18px; }
      .files__table--goBack--arrow {
        cursor: pointer;
        width: 190px;
        display: flex;
        align-items: center;
        justify-content: space-around; }

.switch {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 39px; }

.switch input {
  opacity: 0;
  width: 0;
  height: 0; }

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #d0d0d0;
  transition: 0.4s;
  height: 20px;
  width: 39px; }

.slider:before {
  position: absolute;
  content: '';
  height: 16px;
  width: 16px;
  border-radius: 50%;
  left: 4px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s; }

input:checked + .slider {
  background-color: #75a941; }

input:focus + .slider {
  box-shadow: 0 0 1px #75a941; }

input:checked + .slider:before {
  transform: translateX(16px); }

.round {
  border-radius: 34px; }

.DraftEditor-root {
  background-color: #fff;
  border-radius: 6px;
  border: none;
  width: 100%;
  padding: 19px 10px; }

.form__field {
  position: relative;
  margin: 0 auto 0 auto;
  font-family: "Lato", sans-serif; }
  .form__field .field-label {
    font-weight: 400;
    color: #1a1a1a;
    font-size: 12px;
    pointer-events: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0 5px 2px; }
  .form__field .react-select__control {
    border-style: unset;
    border-radius: 0;
    height: 33px;
    background-color: #fff;
    margin: 5px 0 20px 0;
    border-radius: 5px;
    font-size: 12px !important;
    padding: 0 0 0 10px;
    line-height: 0;
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    color: rgba(26, 26, 26, 0.65); }
    .form__field .react-select__control:hover {
      border-color: #e9e6e6; }
    .form__field .react-select__control--is-focused {
      outline: none;
      box-shadow: none; }
  .form__field .react-select--is-multi__control {
    border-style: unset;
    border-bottom: 1px solid #e9e6e6;
    border-radius: 0;
    min-height: 24px;
    background-color: transparent; }
    .form__field .react-select--is-multi__control:hover {
      border-color: #e9e6e6; }
    .form__field .react-select--is-multi__control--is-focused {
      outline: none;
      box-shadow: none;
      min-height: 24px; }
  .form__field .react-select--is-multi__value-container {
    padding: 0;
    min-height: 24px;
    top: -7px; }
  .form__field .react-select__menu {
    text-align: left; }
  .form__field .react-select__value-container {
    padding: 0;
    min-height: 24px; }
  .form__field .react-select__indicators {
    position: relative; }
    .form__field .react-select__indicators img {
      height: 15px;
      margin-right: 3px; }
  .form__field .react-select__single-value {
    color: #212020;
    font-weight: 300;
    margin-left: 0;
    overflow: visible; }
  .form__field--default input,
  .form__field--default select,
  .form__field--default textarea {
    background-color: #fff !important;
    border-radius: 6px;
    border: none;
    height: 33px;
    width: 100%;
    font-size: 12px !important;
    padding: 19px 10px;
    font-family: "Lato", sans-serif;
    font-weight: 400;
    color: rgba(26, 26, 26, 0.65); }
  .form__field--default textarea {
    height: 100px;
    padding: 10px 10px; }
  .form__field--gray input {
    background-color: #f3f3f3 !important; }
  .form__field--error input,
  .form__field--error textarea,
  .form__field--error .react-select__control {
    border: 1px solid #e20d0d !important; }
  .form__field--error .react-select__input input {
    border: none !important; }
  .form__field--checkbox {
    width: 100%; }
    .form__field--checkbox .checkbox__details {
      display: flex;
      line-height: 25px;
      margin: 5px; }
      .form__field--checkbox .checkbox__details label {
        font-weight: 400 !important;
        color: rgba(26, 26, 26, 0.65);
        font-family: "Lato", sans-serif;
        font-size: 14px; }
    .form__field--checkbox label {
      order: 2;
      cursor: pointer;
      -webkit-user-select: none;
          -ms-user-select: none;
              user-select: none;
      width: calc(100%); }
    .form__field--checkbox input {
      cursor: pointer;
      flex: 0 0 30px; }
  .form__field--disabled input[type='text']::-webkit-input-placeholder {
    color: #dcdbdb; }
  .form__field--disabled input[type='text']:-ms-input-placeholder {
    color: #dcdbdb; }
  .form__field--disabled input[type='text']::placeholder {
    color: #dcdbdb; }
  .form__field .icon {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%); }
  .form__field input,
  .form__field select,
  .form__field textarea {
    width: 100%;
    border: none;
    font-size: 15px;
    background-color: #f3f3f3;
    outline: none; }
    .form__field input option,
    .form__field select option,
    .form__field textarea option {
      color: #444; }
  .form__field input[type='checkbox'] {
    width: 20px;
    height: 17px; }
  .form__field--label-error {
    font-family: "Lato", sans-serif;
    color: #e20d0d;
    margin-left: 3px;
    position: relative;
    animation: show 0.25s ease-in; }

@keyframes show {
  0% {
    right: -40px; }
  50% {
    right: 0px; }
  80% {
    right: -10px; }
  100% {
    right: 0px; } }

input[type='checkbox'] {
  display: none; }

input[type='checkbox'] + .custom-box {
  display: inline-block;
  position: relative;
  top: 3px;
  width: 24px;
  height: 22px;
  margin: -1px 0px 0 0;
  vertical-align: middle;
  background: white left top no-repeat;
  border: 1px solid #ccc;
  cursor: pointer;
  border: 1px solid #707070;
  border-radius: 3px;
  background: transparent; }

input[type='checkbox']:checked + .custom-box {
  background-color: #222d5c;
  background-image: url(/static/media/checked-icon.fc43b4f0.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 70%; }

input[type='checkbox'] + .custom-box {
  margin-right: 15px; }

