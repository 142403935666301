.dashboard {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  &__content {
    margin-top: 124px;
    position: relative;
  }
  @include rwd(1200px) {
    padding: 17px;
    flex-direction: row;
    &__content {
      margin: 0 0 0 400px;
      width: calc(100% - 397px);
      overflow-y: auto;
    }
  }
}
