.login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  &__container {
    font-family: $fontLato;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
  }
  &__header {
    height: 320px;
    position: relative;
    background-color: $cream;
    @include md {
      height: 390px;
    }
  }
  &__logo {
    position: absolute;
    margin-top: 41px;
    margin-left: 17px;
    @include md {
      margin-left: 45px;
    }
    img {
      width: 44px;
      height: 45px;
    }
  }
  &__logo-entire {
    position: absolute;
    bottom: 40px;
    width: 100%;
    text-align: center;
    display: block;
    img {
      width: 213px;
      height: 67px;
    }
  }
  &__form-container {
    width: 100%;
    padding: 50px 0 0 0;
    display: flex;
    justify-content: center;
  }
  &__form {
    // display: none;
    width: 310px;
    .form__field {
      input {
        height: 50px;
        padding: 5px 10px;
        // margin: 12px 0 20px 0;
        border-radius: 5px;
      }
    }
    .btn {
      margin-top: 30px;
    }
  }
  &__forget-password {
    float: right;
    margin: -10px 0 50px 0;
    font-size: 15px;
    font-weight: 400;
    color: $darkBlue;
  }
}
