@import '../variables';

.main-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  // .page {
  //   &__inner {
  //     margin: 20px;
  //   }
  // }
}
