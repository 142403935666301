.settings-list {
  position: relative;
  width: 100%;
  //   margin-right: 17px;
  padding: 15px;
  //   background-color: #f3efec;
  margin: 0 auto;
  @include rwd(501px) {
    width: 450px;
  }
  @include rwd(1200px) {
    height: calc(100vh - 34px);
    width: 450px;
    margin: unset;
  }
  &__title {
    display: flex;
    align-items: center;
    img {
      margin-top: 7px;
      margin-right: 15px;
      cursor: pointer;
    }
    h2 {
      font-size: 17px;
      color: $darkBlue;
      font-family: $fontLato;
      font-weight: bold;
      text-transform: uppercase;
    }
  }
  &__links-container {
    // margin-top: 30px;
    padding-left: 25px;
    height: calc(100% - 107px);
    ul {
      margin: 30px 0 50px 0;
      a {
        // height: 30px;
        line-height: 40px;
        font-size: 13px;
        font-family: $fontLato;
        font-weight: 400;
        letter-spacing: 1.3px;
        text-transform: uppercase;
        color: $darkBlue;
        display: flex;
        position: relative;
        text-decoration: none;
        &:hover {
          font-weight: 900;
          cursor: pointer;
          &:before {
            content: '';
            position: absolute;
            width: 15px;
            top: 48%;
            transform: translateY(-50%);
            right: -10px;
            height: 29px;
            background-image: url(../../../../Img/Icons/active-menu-icon.svg);
            background-repeat: no-repeat;
            background-position: center;
          }
        }
      }
    }
  }
}
