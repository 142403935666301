.home-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h1 {
    font-size: 40px;
  }
  &__button {
    border: 0;
    width: 150px;
    font-size: 20px;
    height: 40px;
    color: #fff;
    background-color: rgb(167, 165, 165);
  }
}
