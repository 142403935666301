.preloader {
  position: fixed;
  z-index: 110;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  //   &__logo {
  //     text-align: center;
  //     position: absolute;
  //     top: 50%;
  //   }
  &__container {
    position: relative;
    width: 60px;
    height: 80px;
  }
  &__container-inner {
    height: 50px;
    width: 50px;
    animation: spin 1.4s infinite linear both;
  }
  &__container-inner--lg {
    width: 40px;
    height: 40px;
    animation: spin 1s infinite linear both;
    animation-delay: 0.4;
  }
  &__container-inner--md {
    width: 30px;
    height: 30px;
    animation: spin 0.9s infinite linear both;
    animation-delay: 0.8;
  }
  &__container-inner--sm {
    width: 20px;
    height: 20px;
    animation: spin 0.6s infinite linear both;
    animation-delay: 1.2;
  }
  &__container-inner,
  &__container-inner--sm,
  &__container-inner--xs,
  &__container-inner--md,
  &__container-inner--lg {
    position: absolute;
    top: 60%;
    left: 50%;
    // transform: translate(-50%, -50%);
    // transform-origin: 50% 50%;
    border-radius: 50%;
    border-left: 3px solid $darkBlue;
    border-top: 3px solid transparent;
    border-right: 3px solid $darkBlue;
    border-bottom: 3px solid transparent;
    &--sm {
      height: 30px;
      width: 30px;
      border-width: 2px;
    }
  }
  &--sm {
    position: absolute;
    z-index: 20;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.6);
  }
}
@keyframes spinBackwards {
  from {
    transform: translate(-50%, -50%) rotate(360deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(0deg);
  }
}

@keyframes spin {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
