.add-reminder {
  position: relative;
  width: 100%;
  //   margin-right: 17px;
  padding: 30px;
  background-color: #f3efec;
  margin: 0 auto;
  @include rwd(501px) {
    width: 380px;
  }
  @include rwd(1200px) {
    height: calc(100vh - 34px);
    width: 380px;
    margin: unset;
  }
  .respond-text {
    font-family: $fontLato;
    font-size: 14px;
    strong {
      font-weight: 700;
    }
  }
  &__title {
    display: flex;
    align-items: center;
    img {
      margin-top: 8px;
      margin-right: 15px;
      cursor: pointer;
    }
    h2 {
      font-size: 17px;
      color: $darkBlue;
      font-family: $fontLato;
      font-weight: bold;
      text-transform: uppercase;
    }
  }
  &__form-container {
    margin-top: 90px;
    border-top: 2px solid $darkBlue;
    height: calc(100% - 107px);
  }
  &__form {
    margin-top: 20px;
    position: relative;
    height: calc(100% - 17px);
    .btn {
      margin-top: 60px;
      @include rwd(1200px) {
        position: absolute;
        bottom: 10px;
      }
    }
    .form__field {
      input,
      textarea {
        // height: 50px;
        // padding: 5px 10px;
        // margin: 5px 0 20px 0;
        border-radius: 5px;
      }
    }
  }
}
