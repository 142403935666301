.caseDetails {
  padding: 15px;
  max-width: 1100px;
  .breadcrumbs {
    margin-bottom: 40px;
  }
  h2 {
    margin-bottom: 30px;
  }
  &__summary,
  &__status {
    margin-bottom: 70px;
  }
  &__summary {
    max-width: 600px;
    &--electronicOnly {
      display: flex;
      align-items: center;
      font-weight: 700;
      font-size: 13px;
      line-height: 16px;
      font-family: Lato, sans-serif;
      margin-left: 245px;
      .switch {
        margin-left: 16px;
      }
    }
  }

  &__summary--actions {
    display: block;
    height: 40px;
  }
  &__print {
    position: absolute;
    right: 0;
    width: 200px;
  }
  &__files {
    position: absolute;
    right: 220px;
    width: 200px;
  }
  .rt-noData {
    display: none;
  }
}

.summary {
  .summaryBlock {
    display: flex;
    width: 100%;
    border-bottom: 1px solid rgba(112, 112, 112, 0.43);
    font-size: 13px;
    line-height: 19px;
    font-family: $fontLato;
    text-align: left;
    font-weight: 700;
    &--pointer {
      cursor: pointer;
    }
    &--hyperlink {
      .summaryBlock__value {
        // text-decoration: underline;
        color: #0000ee;
      }
    }
    &__title {
      padding: 10px 0 10px 10px;
      flex: 0 1 225px;
      background-color: $lightCream;
      p {
        border-right: 1px solid rgba(112, 112, 112, 0.23);
        padding-right: 10px;
        width: 225px;
      }
    }
    &__value {
      padding: 10px;
      flex-grow: 1;
      color: #000000;
      text-decoration: none;
      a {
        color: #000000;
        text-decoration: none;
      }
    }
  }
}

.addCaseRemainder {
  .form__field--date {
    // input {
    //   max-width: 45%;
    // }
    margin-left: 0;
  }
}

.fake_field {
  display: flex;
  justify-content: space-between;
  font-family: 'Lato', sans-serif;
  &__label {
    font-weight: 400;
    color: #1a1a1a;
    font-size: 12px;
    pointer-events: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0px 0 5px 2px;
  }
  &__value {
    color: $darkBlue;
    font-size: 12px;
    font-weight: bold;
  }
}

.case-status {
  display: flex;
  justify-content: space-between;
}
.closeCase {
  background-color: $lightCream;
  padding: 5px 18px;
  font-size: 10px;
  text-transform: uppercase;
  line-height: 14px;
  cursor: pointer;
  border-radius: 5px;
}
.remainderReplies {
  margin-left: -20px;
  margin-right: -20px;
  overflow: auto;
}
.remainderReply {
  font-family: 'Lato', sans-serif;
  font-size: 13px;
  line-height: 1.5;
  background-color: $lightCream;
  border-radius: 6px;
  padding: 15px;
  margin-bottom: 30px;
  width: 100%;

  &__client {
    font-weight: 600;
    color: rgba(46, 46, 46, 0.29);
    margin-bottom: 5px;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  &__caseNo {
    font-weight: 900;
    color: $darkBlue;
  }
  &__date {
    font-weight: 700;
  }
}

.form--inner {
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  width: 100%;
  height: calc(100% - 120px);
  .form__field {
    width: 100%;
  }
}
