.navigation {
  background-color: $cream;

  &__menu {
    height: 104px;
    // box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
    display: flex;
    transition: height 400ms ease;
    justify-content: flex-end;
    background-color: $cream;
    // position: relative;
    position: fixed;

    width: 100%;
    z-index: 101;
    @include rwd(1200px) {
      // position: relative;
      position: fixed;
      width: 380px;
      margin-right: 17px;
    }
    &--desktop {
      display: none;
      font-family: $fontLato;
      font-size: 17px;
      height: 50px;
      cursor: pointer;
      a {
        color: #222;
        text-decoration: none;
        margin: 0 5px;
      }
      li {
        color: #222;
        padding: 15px;
        height: 50px;
        &:hover {
          background-color: #fbfbfb;
          box-shadow: 0px 1px 5px rgba(156, 156, 156, 0.5);
          border-radius: 5px;
        }
      }

      @include rwd(1200px) {
        display: flex;
      }
    }
    &--mobile {
      width: 100%;
      display: flex;
      // justify-content: center;
      align-items: center;
      flex-direction: column;
      height: 100%;
      font-family: $fontLato;
      font-size: 17px;
      padding: 110px 20px 20px 20px;
      cursor: pointer;
      @include rwd(1200px) {
        padding: 110px 30px 20px 30px;
      }
      a {
        text-decoration: none;
        // margin-bottom: 20px;
        width: 100%;
        display: flex;
        align-items: center;
      }
      .logout {
        color: rgba(0, 0, 0, 0.3);
        padding: 15px;
        height: 50px;
        display: flex;
        align-items: center;
        margin-top: 10px;
        img {
          margin-left: 10px;
        }
        @include rwd(1200px) {
          display: none;
        }
      }
      
      .limits {
        display: flex;
        align-items: center;
        width: 100%;
        cursor: auto !important;
        a {
          width: auto;
          margin-right: 15px;
          width: 50px;
        }
        .list{
          &:hover {
            &:after {
              display: none;
            }
          }
        }
        .counter {
          width: 20px;
          height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 13px;
          margin: 0 5px;
          border-radius: 10px;
          &--orange {
            background-color: #EAAA05;
            color: #1E1E1E;
          }
          &--red {
            background-color: #FF5800;
            color: #fff;
          }
        }
      }
      .list {
        position: relative;
        // background-color: rgba(255, 255, 255, 0.67);
        width: 100%;
        border-radius: 6px;
        height: 30px;
        line-height: 30px;
        font-size: 13px;
        font-family: $fontLato;
        font-weight: 400;
        letter-spacing: 1.3px;
        text-transform: uppercase;
        display: flex;
        color: $darkBlue;
        &:hover {
          font-weight: 900;
          // padding-left: 20px;
          &:after {
            content: '';
            position: absolute;
            width: 20px;
            right: 0px;
            height: 29px;
            background-image: url(../../../Img/Icons/active-menu-icon.svg);
            background-repeat: no-repeat;
            background-position: center;
          }
        }
        @include rwd(1200px) {
          background-color: unset;
          font-weight: 400;
        }
        &__icon {
          position: relative;
          // right: 10px;
          margin-right: 10px;
          transform: translateY(-50%);
          top: 50%;
          width: 18px;
        }
        &__counter {
          color: $darkBlue;
          width: 50px;
        }
        &--settings {
          &:before {
            content: '';
            width: 20px;
            height: 20px;
            margin-top: 5px;
            margin-right: 10px;
            background-image: url(../../../Img/Icons/settings-icon.svg);
            background-repeat: no-repeat;
            background-position: center;
            background-size: 16px;
          }
          &:hover {
            // background-repeat: no-repeat;
            // background-position: center;
            // height: 40px;
            // background-color: $darkBlue;
            &:before {
              content: '';
              width: 20px;
              height: 20px;
              margin-top: 5px;
              margin-right: 10px;
              background-image: url(../../../Img/Icons/settings-hover-icon.svg);
              background-repeat: no-repeat;
              background-position: center;
              background-size: 16px;
            }
          }
        }
      }
      // .time-trial {
      //   display: flex;
      //   align-items: center;
      //   justify-content: space-between;
      //   width: 100%;
      //   height: 37px;
      //   font-size: 13px;
      //   color: rgba(0, 0, 0, 0.4);
      //   font-weight: 700;
      //   padding: 0 5px;
      //   margin-bottom: -12px;
      //   span {
      //     color: $darkBlue;
      //     font-size: 25px;
      //     font-weight: 900;
      //     margin-left: 15px;
      //   }
      // }
    }
    &--expanded {
      // min-height: 100vh;
      height: 100%;
      @include rwd(1200px) {
        height: calc(100vh - 34px);
      }
      overflow-y: auto;
      overflow-x: hidden;
      // overflow: hidden;
    }
    &--selected-nav-item {
      .list {
        font-weight: 900;
        // .list__counter {
        //   color: red;
        // }
        &:after {
          content: '';
          position: absolute;
          width: 20px;
          right: 0px;
          height: 29px;
          background-image: url(../../../Img/Icons/active-menu-icon.svg);
          background-repeat: no-repeat;
          background-position: center;
        }
        &--settings {
          &:before {
            background-image: url(../../../Img/Icons/settings-hover-icon.svg);
          }
        }
      }
    }
  }
  &__menu-hamburger {
    --color: #222d5c;
    width: 36px;
    height: 36px;
    padding: 0;
    right: 32px;
    top: 32px;
    // top: 50%;
    // transform: translateY(-50%);
    outline: none;
    position: absolute;
    border: none;
    background: none;
    cursor: pointer;
    -webkit-appearence: none;
    -webkit-tap-highlight-color: transparent;
    svg {
      //   width: 64px;
      width: 84px;
      height: 64px;
      top: -6px;
      left: -14px;
      stroke: var(--color);
      stroke-width: 1px;
      stroke-linecap: round;
      stroke-linejoin: round;
      fill: none;
      display: block;
      position: absolute;
      path {
        transition: stroke-dasharray var(--duration, 0.85s) var(--easing, ease) var(--delay, 0s), stroke-dashoffset var(--duration, 0.85s) var(--easing, ease) var(--delay, 0s);
        stroke-dasharray: var(--array-1, 26px) var(--array-2, 100px);
        stroke-dashoffset: var(--offset, 126px);
        transform: translateZ(0);
        &:nth-child(2) {
          --duration: 0.7s;
          --easing: ease-in;
          --offset: 100px;
          --array-2: 74px;
        }
        &:nth-child(3) {
          --offset: 133px;
          --array-2: 107px;
        }
      }
    }
    &--active {
      svg {
        path {
          --offset: 57px;
          &:nth-child(1),
          &:nth-child(3) {
            --delay: 0.15s;
            --easing: cubic-bezier(0.2, 0.4, 0.2, 1.1);
          }
          &:nth-child(2) {
            --duration: 0.4s;
            --offset: 2px;
            --array-1: 1px;
          }
          &:nth-child(3) {
            --offset: 58px;
          }
        }
      }
    }
    @include rwd(1200px) {
      display: none;
    }
  }
  &__logout {
    right: 25px;
    top: 32px;
    // top: 50%;
    // transform: translateY(-50%);
    outline: none;
    position: absolute;
    border: none;
    background: none;
    cursor: pointer;
    -webkit-appearence: none;
    -webkit-tap-highlight-color: transparent;

    font-family: $fontLato;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.3);
    // padding: 15px;
    height: 50px;
    display: flex;
    align-items: center;

    margin-top: 3px;
    img {
      margin-left: 10px;
      width: 17px;
      height: 15px;
    }
    @media screen and (max-width: 1200px) {
      display: none;
    }
  }
  &__menu-logo {
    position: absolute;
    top: 39px;
    left: 31px;
    display: flex;
    align-items: center;
    img {
      width: 40px;
      height: 41px;
      &:hover {
        cursor: pointer;
      }
    }
    span {
      margin-left: 18px;
      font-family: $fontLato;
      font-weight: bold;
      font-size: 12px;
      color: $darkBlue;
    }
  }
  &__container {
    padding-top: 104px;
  }
  &__list-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-bottom: 2px solid rgba(34, 45, 92, 0.19);
    margin-bottom: 10px;
    .list-title {
      // width: 100%;
      border-radius: 6px;
      display: flex;
      height: 37px;
      line-height: 37px;
      font-size: 13px;
      font-family: $fontLato;
      font-weight: bold;
      letter-spacing: 1.3px;
      text-transform: uppercase;
      color: rgba(34, 45, 92, 0.28);
      &__counter {
        color: $darkBlue;
        width: 50px;
      }
    }
  }
  &__form {
    width: 100%;
    // margin-bottom: 60px;caseNumber
    margin-bottom: 15px;
    @include rwd(1200px) {
      // margin-bottom: 15px;
    }
    .btn {
      margin: 15px 0;
    }

    .form {
      &__field {
        margin-bottom: 14px;
      }
    }
  }
}
