.statistics {
  position: absolute;
  width: 100%;
  height: 100%;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0 15px 17px;
    flex-direction: column;
    @include rwd(601px) {
      flex-direction: row;
    }
  }
  &__title {
    display: flex;
    align-items: center;
    img {
      margin-top: 6px;
      margin-right: 15px;
      cursor: pointer;
    }
    h2 {
      font-size: 17px;
      color: $darkBlue;
      font-family: $fontLato;
      font-weight: bold;
      text-transform: uppercase;
    }
  }
  &__options {
    display: flex;
  }
  &__toggle {
    margin-top: 30px;
    display: flex;
  }
  &__block {
    font-family: $fontLato;
    display: flex;
    font-size: 14px;
    align-items: center;
    justify-content: center;
    width: 170px;
    height: 37px;
    border-radius: 6px;
    background-color: #f3f3f3;
    text-transform: uppercase;
    color: rgba(34, 45, 92, 0.49);
    font-weight: bold;
    cursor: pointer;

    &:first-child {
      margin-right: -5px;
    }

    &--active {
      background-color: rgba(34, 45, 92, 1);
      color: white;
      z-index: 10;
    }
  }
  .externalPagination {
    margin-top: 20px;
  }
  &__table {
    margin-top: 30px;
  }
}
