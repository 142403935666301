.cases-list {
  //   position: absolute;
  //   width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  &__link {
    text-decoration: none;
    color: #344594;
  }
  &__summary {
    position: relative;
    display: flex;
    //   margin-right: 17px;
    padding-left: 25px;
    background-color: #f3efec;
    margin: 0 auto;
    transition: 0.3s;
    @include rwd(501px) {
      max-width: 380px;
    }
    @include rwd(1200px) {
      height: calc(100vh - 34px);
      max-width: 430px;
      margin: unset;
      overflow-y: scroll;
    }
    &--rolled-up {
      max-width: 40px;
      padding: 0;
      margin-left: -20px;
      overflow: hidden;
      right: -5px;
    }
    &--content {
      width: 410px;
      max-width: 100%;
      padding-top: 25px;
      transition: 0.3s;
      padding-right: 30px;
    }
    &__title {
      display: flex;
      align-items: center;
      img {
        margin-top: 8px;
        margin-right: 15px;
        cursor: pointer;
      }
      h2 {
        font-size: 17px;
        color: $darkBlue;
        font-family: $fontLato;
        font-weight: bold;
        text-transform: uppercase;
      }
    }
    &__company {
      margin-top: 20px;
      font-size: 17px;
      color: $darkBlue;
      font-family: $fontLato;
      font-weight: bold;
      // text-transform: uppercase;
    }
    &__form-container {
      margin-top: 90px;
      border-top: 2px solid $darkBlue;
      height: calc(100% - 107px);
    }
    &__form {
      margin-top: 20px;
      position: relative;
      height: calc(100% - 17px);
      .btn {
        margin-top: 60px;
        @include rwd(1200px) {
          position: absolute;
          bottom: 10px;
        }
      }
      .form__field {
        input,
        textarea {
          // height: 50px;
          // padding: 5px 10px;
          // margin: 5px 0 20px 0;
          border-radius: 5px;
        }
      }
    }
  }
  &__short-summary {
    display: flex;
    justify-content: space-between;
    padding: 25px 0;
    // line-height: 13px;
    line-height: 20px;
    align-items: flex-start;
    p {
      color: rgba(0, 0, 0, 0.4);
      font-size: 13px;
      font-weight: 400;
      font-family: $fontLato;
      &:first-of-type {
        line-height: 8px;
      }
    }
    span {
      margin-left: 10px;
    }
    &--in-progress {
      color: $orange;
      font-size: 25px;
      font-weight: 900;
    }
    &--done {
      color: $darkBlue;
      font-size: 25px;
      font-weight: 900;
    }
  }
  &__cases-status {
    display: flex;
    border-bottom: 2px solid rgba(34, 45, 92, 0.19);
    justify-content: flex-end;
    margin-bottom: 10px;
    p {
      font-size: 12px;
      font-family: $fontLato;
      font-weight: 600;
      margin-left: 12px;
      margin-bottom: 5px;
    }
    &--in-progress {
      color: $orange;
    }
    &--all {
      color: rgba(0, 0, 0, 1);
    }
    &--done {
      color: rgba(0, 0, 0, 0.4);
    }
  }
  &__cases-types {
    color: $darkBlue;
    font-size: 13px;
    font-weight: 400;
    font-family: $fontLato;
    text-transform: uppercase;
    letter-spacing: 1.4px;
    ul {
      li {
        padding-top: 10px;
        display: flex;
        justify-content: space-between;
        padding-bottom: 10px;
        position: relative;
        &:hover {
          border-bottom: 1px solid rgba(34, 45, 92, 0.22);
          font-weight: 900;
          padding-left: 25px;
          cursor: pointer;
          &:before {
            content: '';
            position: absolute;
            width: 20px;
            left: 0px;
            top: 49%;
            transform: translateY(-50%);
            height: 40px;
            background-image: url(../../../../Img/Icons/active-menu-icon.svg);
            background-repeat: no-repeat;
            background-position: center;
          }
        }
        span {
          //   margin-left: 15px;
          font-weight: 700;
        }
      }
    }
    &--selected {
      border-bottom: 1px solid rgba(34, 45, 92, 0.22);
      font-weight: 900;
      padding-left: 25px;
      position: relative;
      &:before {
        content: '';
        position: absolute;
        width: 20px;
        left: 0px;
        top: 49%;
        transform: translateY(-50%);
        height: 40px;
        background-image: url(../../../../Img/Icons/active-menu-icon.svg);
        background-repeat: no-repeat;
        background-position: center;
      }
    }
    .container {
      display: flex;
    }
    &--element {
      text-align: left !important;
    }
    &--in-progress {
      color: $orange;
      padding-right: 22px;
    }
    &--all {
      color: $darkBlue;
      padding-right: 15px;
    }
    &--done {
      color: rgba(34, 45, 92, 0.38);
    }
    &--in-progress,
    &--all,
    &--done {
      width: 60px;
      display: block;
      text-align: right;
    }
  }
  &__expander {
    width: 25px;
    padding: 0 1px 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: #eae7e4;
    margin-left: 20px;
    border-left: 1px solid rgba(0, 0, 0, 0.053);
    transition: 0.3s;
    height: 100%;
    position: absolute;
    right: 0;
    &:hover {
      background-color: rgb(219, 215, 212);
      transition: 0.3s;
    }
    &--search-engine {
      margin-left: 0;
      border-left: none;
      left: 0;
      right: auto;
      position: relative;
    }
    &--rolled-up {
      position: absolute;
      transition: 0.3s;
    }
    &--icon-right {
      transform: rotate(180deg);
    }
  }

  &__container {
    width: calc(100% - 350px);
    transition: 0.3s;
    &--full-width {
      width: 100%;
    }
    &--limits {
      // max-width: 936px;
      width: 100%;
      margin-left: 40px;
      margin-top: 30px;
      @media (max-width: 1200px) {
        margin-right: 40px;
      }
      @media (max-width: 756px) {
        margin-left: 0px;
      }
    }
    &--rolled-up {
      width: calc(100% - 60px);
    }
    &--without-scroll {
      max-height: calc(100vh - 35px);
      overflow: hidden;
      .cases-list__table {
        height: calc(100% - 129px);
        padding-bottom: 20px;
        overflow: scroll;
      }
    }
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 15px 0 15px 17px;
  }
  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .btn--default {
      width: unset;
    }
    img {
      margin-top: 6px;
      margin-right: 15px;
      cursor: pointer;
    }
    h2 {
      font-size: 17px;
      color: $darkBlue;
      font-family: $fontLato;
      font-weight: bold;
      text-transform: uppercase;
    }
  }
  &__table {
    height: calc(100% - 55px);
    overflow-x: scroll;
    overflow-y: auto;

    &--search-engine {
      height: calc(100% - 100px);
    }
    thead th {
      position: sticky;
      top: 0;
    }

    th {
      z-index: 101;
      background: #fff;
    }

    table {
      table-layout: unset;
      tbody {
        .actions {
          display: flex;
          flex-direction: row;
          align-items: center;
          position: relative;
          &--electronicOnly::before {
            position: absolute;
            content: '';
            width: 12px;
            height: 12px;
            border-radius: 100%;
            left: -5px;
            background: #75a941;
          }
          //   height: -webkit-fill-available;
        }
        tr {
          height: unset;
        }
        td {
          vertical-align: middle;
          white-space: nowrap;
          padding: 2px 15px 10px 15px;
        }
      }
    }
  }
}
