.add-customer,
.narrow-section {
  position: relative;
  width: max-content;
  //   margin-right: 17px;
  padding: 30px;
  background-color: #f3efec;
  margin: 0 auto;
  @include rwd(501px) {
    // width: 380px;
  }
  @include rwd(1200px) {
    height: calc(100vh - 34px);
    // width: 380px;
    margin: unset;
  }
  &__title {
    display: flex;
    align-items: center;
    img {
      margin-top: 8px;
      margin-right: 15px;
      cursor: pointer;
    }
    h2 {
      font-size: 17px;
      color: $darkBlue;
      font-family: $fontLato;
      font-weight: bold;
      text-transform: uppercase;
    }
  }
  &__form-container {
    margin-top: 90px;
    border-top: 2px solid $darkBlue;
    height: calc(100% - 107px);
    display: flex;
    justify-content: space-around;
  }
  &__added-clients {
    min-width: max-content;
  }
  &__form {
    margin-top: 20px;
    width: 300px;
    position: relative;
    height: calc(100% - 17px);
    overflow-y: scroll;
    .btn {
      margin-top: 60px;
      @include rwd(1200px) {
        position: absolute;
        // bottom: 10px;
      }
    }
    .form__field {
      input,
      textarea {
        // height: 50px;
        // padding: 5px 10px;
        // margin: 5px 0 20px 0;
        border-radius: 5px;
      }
    }
    &--type-list {
      font-family: $fontLato;
      font-size: 15px;
      color: rgba(26, 26, 26, 0.85);
    }
    &--padding {
      padding: 0 10px;
    }
  }
  &--details {
    max-width: 360px;
    &__inner {
      height: calc(100% - 30px);
      position: relative;
    }
    &__wrapper {
      display: flex;
    }
    &__summary {
      max-width: 630px;
      width: 100%;
      padding: 30px;
      .btn--addNewCustomer {
        margin-top: 60px;
      }
    }
    &__userInfo {
      display: flex;
      width: 100%;
      font-size: 13px;
      line-height: 1.5;
      font-weight: bold;
      padding: 10px 0;
      font-family: 'Lato', sans-serif;
      &--name {
        width: 33%;
        padding: 5px 15px 5px 0;
        border-right: 1px solid rgba(112, 112, 112, 0.22);
      }
      &--phone {
        width: 22%;
        padding: 5px 15px;
        border-right: 1px solid rgba(112, 112, 112, 0.22);
      }
      &--email {
        width: 35%;
        padding: 5px 15px;
      }
      &--actions {
        cursor: pointer;
        padding: 5px 0 5px 15px;
        width: 10%;
        text-align: right;
        display: flex;
        justify-content: flex-end;
        .btn-action--edit {
          margin-right: 0;
        }
      }
    }
    &__actions {
      text-align: right;
    }
    &__addCustomerButton {
      display: inline-block;
    }
  }

  &__subtitle {
    font-size: 13px;
    line-height: 1.5;
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    letter-spacing: 1.3px;
    text-transform: uppercase;
    color: #222d5c;
    margin: 15px 0;
    &--margintop {
      margin-top: 60px;
      display: inline-block;
    }
  }
  &__newClient {
    display: flex;
    font-size: 13px;
    line-height: 1.5;
    font-weight: bold;
    width: 100%;
    padding: 10px 0;
    font-family: 'Lato', sans-serif;
    border-bottom: 1px solid rgba(112, 112, 112, 0.22);
    &--name {
      width: 33%;
      padding: 5px 15px 5px 0;
      border-right: 1px solid rgba(112, 112, 112, 0.22);
    }
    &--casesType {
      padding: 5px 15px;
    }
    &--actions {
      margin-left: auto;
      padding: 5px 0 5px 15px;
      text-align: right;
      .btn-action--delete {
        margin-right: 0;
      }
    }
  }
}

button {
  &:disabled {
    cursor: not-allowed;
  }
}

.add-customer__form {
  .react-select__menu {
    height: auto;
    padding-bottom: 20px;
  }
}