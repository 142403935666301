.confirmation-popup {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 120;
  background: rgba(0, 0, 0, 0.45);
  top: 0;
  left: 0;
  right: 0;
  &__inner {
    position: relative;
    animation: showPopup 0.19s ease-in-out;
    width: 380px;
    //height: 210px;
    border-radius: 2px;
    margin: 0 10px;
    background: #fff;
    box-shadow: 0px 2px 5px rgba(56, 20, 20, 0.22);
    .fa-times {
      position: absolute;
      top: 10px;
      right: 10px;
      font-size: 25px;
      color: #827d7d;
    }
    &--hidePopup {
      animation: hidePopup 0.19s ease-in-out;
    }
  }
  &__content {
    text-align: left;
    // padding: 15px 0;
    //padding: 15px 10px 0 10px;
    h2 {
      font-family: $fontLato;
      text-transform: uppercase;
      font-weight: 700;
      letter-spacing: 1px;
      color: $darkBlue;
      font-size: 17px;
      background-color: $cream;
      padding: 15px 20px;
    }
    h6 {
      font-family: $fontLato;
      padding: 30px 0;
      color: #2e2e2e;
      font-weight: 700;
      font-size: 13px;
      margin: 0 20px;
      border-bottom: 2px solid rgba(34, 45, 92, 0.19);
    }
  }
  &__buttons {
    display: flex;
    justify-content: space-between;
    margin: 15px 20px;
  }
}

@keyframes showPopup {
  0% {
    // transform: translateX(300px);
    transform: translateY(-400px);
    opacity: 0;
  }
  100% {
    // transform: translateX(0px);
    transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes hidePopup {
  0% {
    // transform: translateX(300px);
    transform: translateY(0px);
    opacity: 1;
  }
  100% {
    transform: translateY(-400px);
    opacity: 0;
    // transform: translateX(0px);
  }
}
